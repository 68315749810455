$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let language = $("body").attr("lang");
  const menuProducts = [];
  $(document).on(
    "keydown",
    "#ticket_price_ex_vat, #ticket_price_incl_vat, #degree_of_coverage, #coverage_contribution, #price-inc-mva-value, #price-ex-mva-value, #volume",
    function (event) {
      if (event.key == "-") {
        event.preventDefault();
      }
    }
  );

  $(document).on(
    "input",
    "#ticket_price_ex_vat, #ticket_price_incl_vat, #degree_of_coverage, #coverage_contribution, #price-inc-mva-value, #price-ex-mva-value, #volume",
    function () {
      if (this.value.length > 1 && this.value.indexOf(0) == 0) {
        $(this).val(Math.abs(this.value));
      }
    }
  );

  $(document).on("change", "#is_volume", function () {
    if ($(this).prop("checked")) {
      $("#volume").prop("required", true);
      $("#volume").prop("type", "number");
    } else {
      $("#volume").removeAttr("required");
      $("#volume").prop("type", "hidden");
    }
  });

  if (p == "organizer_event_ticket_category" && a == "update") {
    let degreeOfCoverage = $("#degree_of_coverage").val();
    let coverageOfContribution = $("#coverage_contribution").val();
    let productPrice = $("#ticket_price_ex_vat").val();
    let mva = $("#vat_code").children("option:selected").text();
    let priceExMva = $("#price-ex-mva-value").val();
    let priceIncMva = $("#price-inc-mva-value").val();
    let event_ticket_category_update_ajax_lock = false;

    $(document).on("blur", "#product-sku-input", function (e) {
      e.preventDefault();
      const encodedData = $("#existing_product_number").attr(
        "data-product-number-list"
      );
      const existingNumberList = JSON.parse(atob(encodedData));
      const newValue = $(this).val();
      const oldValue = $(this).attr("data-old-value");
      const filteredexistingNumbers = existingNumberList.filter(
        (number) => number !== oldValue
      );

      if (filteredexistingNumbers.includes(newValue)) {
        alert("varenummer finnes allerede!");
        $(this).val(oldValue);
        $(this).focus();
        return;
      }
    });

    $(document).on("change", "#ticket_price_ex_vat", function (e) {
      e.preventDefault();
      productPrice = $(this).val();
      $("#coverage_contribution").val(
        formattedValue(
          parseFloat(productPrice) * parseFloat(degreeOfCoverage / 100)
        )
      );
      coverageOfContribution = formattedValue(
        parseFloat(productPrice) * parseFloat(degreeOfCoverage / 100)
      );
      priceExMva = formattedValue(
        parseFloat(productPrice) + coverageOfContribution
      );
      $("#price-ex-mva-value").val(priceExMva);
      priceIncMva = formattedValue(priceExMva * (1 + parseInt(mva) / 100));
      $("#price-inc-mva-value").val(priceIncMva);
    });

    $(document).on("change", "#degree_of_coverage", function (e) {
      e.preventDefault();
      degreeOfCoverage = $(this).val();
      $("#coverage_contribution").val(
        formattedValue(
          parseFloat(productPrice) * parseFloat(degreeOfCoverage / 100)
        )
      );
      coverageOfContribution = formattedValue(
        parseFloat(productPrice) * parseFloat(degreeOfCoverage / 100)
      );
      priceExMva = formattedValue(
        parseFloat(productPrice) + coverageOfContribution
      );
      $("#price-ex-mva-value").val(priceExMva);
      priceIncMva = formattedValue(priceExMva * (1 + parseInt(mva) / 100));
      $("#price-inc-mva-value").val(priceIncMva);
    });

    $(document).on("change", "#coverage_contribution", function (e) {
      e.preventDefault();
      coverageOfContribution = $(this).val();
      $("#degree_of_coverage").val(
        formattedValue(
          (parseFloat(coverageOfContribution) / parseFloat(productPrice)) * 100
        )
      );
      priceExMva = formattedValue(
        parseFloat(productPrice) + parseFloat(coverageOfContribution)
      );
      $("#price-ex-mva-value").val(priceExMva);
      priceIncMva = formattedValue(priceExMva * (1 + parseInt(mva) / 100));
      $("#price-inc-mva-value").val(priceIncMva);
    });

    $(document).on("change", "#price-ex-mva-value", function (e) {
      e.preventDefault();
      priceExMva = $(this).val();

      if (priceExMva < productPrice + coverageOfContribution) {
        alert("Verdien bør være større enn Innpris + DB");
        $(this).focus();
        return;
      }
      priceIncMva = formattedValue(parseFloat(priceExMva) * (1 + mva / 100));
      $("#price-inc-mva-value").val(priceIncMva);
      coverageOfContribution = formattedValue(
        parseFloat(priceExMva) - parseFloat(productPrice)
      );
      $("#coverage_contribution").val(coverageOfContribution);
      degreeOfCoverage = formattedValue(
        (coverageOfContribution / productPrice) * 100
      );
      $("#degree_of_coverage").val(degreeOfCoverage);
    });

    $(document).on("change", "#price-inc-mva-value", function (e) {
      e.preventDefault();
      priceIncMva = $(this).val();

      if (priceIncMva < productPrice * (1 + mva / 100)) {
        alert("Verdien bør være større enn Innpris + MVA % ");
        $(this).focus();
        return;
      }
      priceExMva = formattedValue(priceIncMva / (1 + mva / 100));
      $("#price-ex-mva-value").val(priceExMva);
      coverageOfContribution = formattedValue(
        parseFloat(priceExMva) - parseFloat(productPrice)
      );
      $("#coverage_contribution").val(coverageOfContribution);
      degreeOfCoverage = formattedValue(
        (coverageOfContribution / productPrice) * 100
      );
      $("#degree_of_coverage").val(degreeOfCoverage);
    });

    $(document).on("change", "#vat_code", function (e) {
      e.preventDefault();
      mva = $(this).children("option:selected").text();
      priceIncMva = formattedValue(
        parseFloat(priceExMva) * (1 + parseInt(mva) / 100)
      );
      $("#price-inc-mva-value").val(priceIncMva);
    });

    // update existing menu product state
    $("#menu-product-table-body")
      .children()
      .each(function () {
        const productId = $(this).find(".amount-input").data("product-id"); // Extract product ID from the ID attribute
        const amount = $(this).find(".amount-input").val(); // Get the value of amount input
        // Create an object with product_id and amount and push it to menuProducts array
        menuProducts.push({ product_id: productId, amount: amount });
      });

    $(document).on("submit", "#choose-product-search-form", function (e) {
      e.preventDefault();
      let search_string = $("#choose_product_search_string").val();
      let location_id = $("body").attr("event_id");
      let product_type = 0;

      getProductList(location_id, search_string, product_type);
    });

    $(document).on("click", ".menu-product-item", function () {
      const productName = $(this).data("menu-product-name");
      const productId = $(this).attr("id");
      const productVolume = $(this).data("menu-product-volume");
      const tableBody = $("#menu-product-table-body");
      const menuProduct = {
        product_id: productId,
        name: productName,
        amount: 1,
      };

      const newRow = `
        <tr id="menu-product-${productId}">
            <td class="name-column">${productName}</td>
            <td>
                <div class="input-group">
                    <input type="number" data-product-id="${productId}" class="form-control amount-input" value="1" min="0" step="any"
                        onfocus="this.select()">
                    <div class="input-group-append">
                        <span class="input-group-text">${productVolume}</span>
                    </div>
                </div>
            </td>
            <td class="text-center">
                <button type="button" data-menu-product-id="${productId}" class="btn btn-danger btn-sm remove-menu-product">${
        language === "no" ? "Fjerne" : "Remove"
      }</button>
            </td>
        </tr>
    `;
      tableBody.append(newRow);

      menuProducts.push(menuProduct);

      $("#choose-product-modal").modal("hide");
    });

    $(document).on(
      "input",
      "#product-discount-price-input, #product-discount-percent-input, #discount-percent-input",
      function () {
        if (this.value.length > 1 && this.value.indexOf(0) == 0) {
          $(this).val(Math.abs(this.value));
        }
      }
    );

    $(document).on(
      "keydown",
      "#product-discount-price-input, #discount-percent-input, #product-discount-percent-input",
      function (event) {
        if (event.key === "-") {
          event.preventDefault();
        }
      }
    );

    $(document).on("change", ".amount-input", function () {
      const value = parseFloat(this.value) || 0;
      const productId = $(this).data("product-id");

      const index = menuProducts.findIndex(
        (product) => product.product_id == productId
      );

      if (index !== -1) {
        menuProducts[index].amount = value;
      }
    });

    $(document).on("click", ".remove-menu-product", function (e) {
      e.preventDefault();
      const productId = $(this).data("menu-product-id");
      $(this).closest("tr").remove();

      const index = menuProducts.findIndex(
        (product) => product.product_id == productId
      );

      if (index !== -1) {
        menuProducts.splice(index, 1);
      }
    });

    $(document).on("change", "#is_menu_product", function () {
      if ($(this).is(":checked")) {
        $("#choose-product-button").show();
      } else {
        $("#choose-product-button").hide();
      }
    });

    function formattedValue(value) {
      if (Number.isInteger(value)) {
        // If it's an integer, return it as is
        return value;
      } else {
        // If it's not an integer, format it to two decimal places
        let returnValue = +(Math.floor(value * 100) / 100).toFixed(2);
        return returnValue;
      }
    }
    if (
      $("#event_ticket_category_id").val() !== undefined &&
      $("#event_ticket_category_id").val() != null &&
      $("#event_ticket_category_id").val() !== ""
    ) {
      $(document).on("submit", "#event_ticket_category_form", function () {
        if (!event_ticket_category_update_ajax_lock) {
          let event_id = $("#event_id").val();

          event_ticket_category_update_ajax_lock = true;

          $(":disabled").each(function () {
            $(this).removeAttr("disabled");
          });

          let formData = new FormData(
            document.getElementById("event_ticket_category_form")
          );
          let productCategoryPOSIds = formData.getAll("product-category-pos");
          let productCategorySelfServiceIds = formData.getAll(
            "product-category-self-service"
          );
          if (productCategoryPOSIds.length > 0) {
            formData.append("product_category_pos_list", productCategoryPOSIds);
          }
          if (productCategorySelfServiceIds.length > 0) {
            formData.append(
              "product_category_self_service_list",
              productCategorySelfServiceIds
            );
          }

          if (menuProducts.length > 0) {
            formData.append("menu_products", JSON.stringify(menuProducts));
          }

          formData.append("price_out_ex_mva", priceExMva);
          formData.append("ticket_price_out_inc_mva", priceIncMva);
          $.ajax({
            type: "POST",
            url: "/ajax/organizer_event_ticket_category/update",
            data: formData,
            enctype: "multipart/form-data",
            async: true,
            cache: false,
            processData: false,
            contentType: false,
          })
            .done(function (data) {
              if (data.message !== undefined && data.message !== null) {
                prependAlert(
                  "#div_event_ticket_category_button",
                  data.message,
                  "success",
                  null,
                  "#event_ticket_category_form"
                );
                $(".card-box-to-hide").hide();
                $("#event_ticket_category_button").hide();

                setTimeout(function () {
                  document.location.href =
                    "/location/" + event_id + "/product/";
                }, 2000);
              }
            })
            .fail(function (data) {
              if (
                data.responseJSON.redirect_url !== undefined &&
                data.responseJSON.redirect_url !== null
              ) {
                document.location.href = data.responseJSON.redirect_url;
              }

              if (
                data.responseJSON.error_message !== undefined &&
                data.responseJSON.error_message !== null
              ) {
                prependAlert(
                  "#div_event_ticket_category_button",
                  data.responseJSON.error_message,
                  "danger",
                  null,
                  "#event_ticket_category_form"
                );
              }

              event_ticket_category_update_ajax_lock = false;
            })
            .always(function () {
              event_ticket_category_update_ajax_lock = false;
            });
        }

        return false;
      });
    }

    function getProductList(location_id, search_string, product_type) {
      $.ajax({
        type: "POST",
        url: "/ajax/organizer_event_ticket_category/menu_product_search",
        data: {
          location_id,
          search_string,
          product_type,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#choose-product-list-container").empty();
            $("#choose-product-list-container").append(data.html);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }
  }
});
