$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let order = $("body").attr("order") || null;

  if (p === "order_confirmation") {
    let isPOS = new URLSearchParams(window.location.search).get("shop_type")
      ? true
      : false;

    const routeToPOS = $("#route-to-pos").attr("href");

    if (isPOS && !order) {
      setTimeout(function () {
        document.location.href = routeToPOS;
      }, 10000);
    }

    return false;
  }
});
