$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  let organizerId = $("body").attr("organizer_id");
  let status = $("body").attr("status");
  let url = "";
  let terminalId = null;
  if (p === "terminal" || p === "admin_organizer_setting") {
    $(document).on("submit", "#terminal-registration-form", function (e) {
      e.preventDefault();

      const formData = new FormData($(this)[0]);
      formData.append("organizer_id", organizerId);
      url = "/api/v1/private/terminal/new"; ///api/v1/private/order/delete
      saveTerminal(formData, url);
    });

    //update
    $(document).on("submit", "#terminal-update-form", function (e) {
      e.preventDefault();
      const formData = new FormData(this);
      formData.append("organizer_id", organizerId);
      formData.append("status", status);
      url = "/api/v1/private/terminal/update";
      saveTerminal(formData, url);
    });

    //delete
    $(document).on("click", ".terminal-delete-btn", function (e) {
      e.preventDefault();
      let idToDelete = $(this).attr("data-delete-id");
      $("#delete-terminal-confirm").attr("data-delete-id", idToDelete);
    });

    $(document).on("click", "#delete-terminal-confirm", function (e) {
      e.preventDefault();

      organizerId = $(this).data("organizer-id");
      terminalId = $(this).attr("data-delete-id");
      url = "/api/v1/private/terminal/delete";
      const formData = new FormData();
      formData.append("status", 0);
      formData.append("id", terminalId);
      formData.append("organizer_id", organizerId);
      updateStatus(formData, url);
      $(".modal").modal("hide");
    });

    $(document).on("click", ".sort-terminal", function (e) {
      e.preventDefault();
      locationId = $("#terminal-table").attr("data-location-id");
      let order_by = $(this).attr("data-order-by");
      let order_by_direction = $(this).attr("data-order-by-direction");
      getTerminalList(locationId, order_by, order_by_direction, 1);
    });

    function getTerminalList(
      location_id = null,
      order_by,
      order_by_direction,
      status
    ) {
      $.ajax({
        type: "POST",
        url: "/api/v1/private/terminal/search",
        data: {
          location_id,
          order_by,
          order_by_direction,
          status,
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#terminal-list-view").empty();
            $("#terminal-list-view").append(data.html);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function saveTerminal(formData, url) {
      $.ajax({
        type: "POST",
        url,
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.message !== undefined && data.message !== null) {
            prependAlert("#message", data.message, "success", null);
            $("#terminal-registration-form").hide();
            $("#terminal-update-form").hide();
            $("#message").show();
            $("html,body").scrollTop(0);

            setTimeout(function () {
              document.location.href = `/admin/organizers/${organizerId}/edit#terminal-setting`;
            }, 2000);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#message-box",
              data.responseJSON.error_message,
              "danger",
              null,
              "#supplier-registration-form"
            );
          }
        });
    }

    function updateStatus(formData, url) {
      $.ajax({
        type: "POST",
        url,
        data: formData,
        enctype: "multipart/form-data",
        async: true,
        cache: false,
        processData: false,
        contentType: false,
      })
        .done(function (data) {
          if (data.message !== undefined && data.message !== null) {
            $("tr#terminal-" + terminalId).fadeOut(1000);
            setTimeout(function () {
              $("tr#terminal-" + terminalId).remove();
            }, 1000);
            $("#terminal-mobile-list-" + terminalId).fadeOut(1000);
            setTimeout(function () {
              $("#terminal-mobile-list-" + terminalId).remove();
            }, 1000);
            popupAlert();
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#message-box",
              data.responseJSON.error_message,
              "danger",
              null,
              "#table-update-form"
            );
          }
        });
    }
  }
});
