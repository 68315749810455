$(document).ready(function () {
  let p = $("body").attr("p");
  if (p === "order_confirmation") {
    $(document).on("submit", "#receipt-in-email", function () {
      $(".alert").remove();
      const submitButton = $(this).find("button[type=submit]");
      const buttonText = submitButton.text();

      submitButton.prop("disabled", true).empty().html('<div class="loader"></div>');

      $.ajax({
        type: "POST",
        url: "/ajax/order/send_receipt_in_email",
        data: {
          serialized_post: $(this).serialize(),
        },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            prependAlert(
              "#receipt-in-email-button-wrapper",
              data.html,
              "success",
              null,
              "#receipt-in-email"
            );
            $("#receipt-in-email").remove();
            submitButton.prop("disabled", false).text(buttonText);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.error_message !== undefined &&
            data.responseJSON.error_message !== null
          ) {
            prependAlert(
              "#receipt-in-email-button-wrapper",
              data.responseJSON.error_message,
              "danger",
              null,
              "#receipt-in-email"
            );
          }
          submitButton.prop("disabled", false).text(buttonText);
        });

      return false;
    });

    $(document).on("click", "#print-order", function () {
      const location_id = $(this).data("location-id");
      const confirmation_code = $(this).data("order-confirmation-code");

      $.ajax({
        type: "POST",
        url: "/ajax/printer/get_printer_list_modal",
        data: { location_id, confirmation_code },
      })
        .done(function (data) {
          if (data.html !== undefined && data.html !== null) {
            $("#printer").after(data.html);
            $("#printer-list-modal").modal("show");
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    });
  }

  // if (!window.ReactNativeWebView) {
  //   $("#print-order").remove();
  // }
});

function passDataToWebView(
  event,
  action,
  data,
  ip = "",
  type = "",
  organizer,
  order
) {
  event.preventDefault();
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action,
        data,
        ip,
        type,
        organizer,
        order,
      })
    );
  }
}
