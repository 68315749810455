$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");

  if (p === "statistic_vat") {
    handleCustomerIdChange(getVatPercent);
    handleCustomPeriodChange(getVatPercent);
    handleTopProductGroupChange(getVatPercent);
    handleSupplierIdChange(getVatPercent);
    handleProductGroupChange(getVatPercent);
    handleUserChange(getVatPercent);
    handlePlatformChange(getVatPercent);
    handlePeriodChange(getVatPercent);
    handleEndDateChange(getVatPercent);
    handleStartDateChange(getVatPercent);
    handlePreviousButton(getVatPercent);
    handleNextButton(getVatPercent);
    handleCompareByChange(getVatPercent);

    function eventLineChart(seriesData, interval = null) {
      let categories = seriesData.map((item) => item.name); // Extract dates for x-axis categories

      // Prepare the series array
      let series = [
        {
          name: "MVA 0%",
          data: seriesData.map((item) => item.data[0][0]), // First line of Vipps
          stack: "MVA 0%",
        },
        {
          name: "MVA 12%",
          data: seriesData.map((item) => item.data[0][1]), // First line of Kort
          stack: "MVA 12%",
        },
        {
          name: "MVA 15%",
          data: seriesData.map((item) => item.data[0][2]), // First line of Terminal
          stack: "MVA 15%",
        },
        {
          name: "MVA 25%",
          data: seriesData.map((item) => item.data[0][3]), // First line of Kontanter
          stack: "MVA 25%",
        },
      ];

      // Conditionally add last week and last year data if compareBy is not null or "0"
      if (compareBy && compareBy !== "0") {
        series = [
          {
            name: "MVA 0%",
            data: seriesData.map((item) => item.data[0][0]), // First line of MVA 0%
            stack: "MVA 0%",
          },
          {
            name: "MVA 0% forrige uke",
            data: seriesData.map((item) => item.data[1][0]), // Second line of MVA 0%
            stack: "MVA 0%", // Same stack as "MVA 0%" to group the columns
          },
          {
            name: "MVA 0% forrige år",
            data: seriesData.map((item) => item.data[2][0]), // Third line of MVA 0%
            stack: "MVA 0%", // Same stack as "Vipps" to group the columns
          },
          {
            name: "MVA 12%",
            data: seriesData.map((item) => item.data[0][1]), // First line of MVA 12%
            stack: "MVA 12%",
          },
          {
            name: "MVA 12% forrige uke",
            data: seriesData.map((item) => item.data[1][1]), // Second line of MVA 12%
            stack: "MVA 12%", // Same stack as "MVA 12%" to group the columns
          },
          {
            name: "MVA 12% forrige år",
            data: seriesData.map((item) => item.data[2][1]), // Third line of MVA 12%
            stack: "MVA 12%", // Same stack as "Kort" to group the columns
          },
          {
            name: "MVA 15%",
            data: seriesData.map((item) => item.data[0][2]), // First line of MVA 15%
            stack: "MVA 15%",
          },
          {
            name: "MVA 15% forrige uke",
            data: seriesData.map((item) => item.data[1][2]), // Second line of MVA 15%
            stack: "MVA 15%", // Same stack as "MVA 15%" to group the columns
          },
          {
            name: "MVA 15% forrige år",
            data: seriesData.map((item) => item.data[2][2]), // Third line of MVA 15%
            stack: "MVA 15%", // Same stack as "MVA 15%" to group the columns
          },
          {
            name: "MVA 25%",
            data: seriesData.map((item) => item.data[0][3]), // First line of MVA 25%
            stack: "MVA 25%",
          },
          {
            name: "MVA 25% forrige uke",
            data: seriesData.map((item) => item.data[1][3]), // Second line of MVA 25%
            stack: "MVA 25%", // Same stack as "MVA 25%" to group the columns
          },
          {
            name: "MVA 25% forrige år",
            data: seriesData.map((item) => item.data[2][3]), // Third line of MVA 25%
            stack: "MVA 25%", // Same stack as "MVA 25%" to group the columns
          },
        ];
      }

      // Render the chart
      Highcharts.chart("event-line-graph", {
        chart: { type: "column" },
        title: {
          text: null,
        },
        exporting: { enabled: false },
        plotOptions: {
          series: {
            lineWidth: 2, // Adjust line width as needed
            stacking: "normal", // Enable stacking of columns in the same group
          },
        },
        xAxis: {
          categories: categories,
          labels: {
            formatter: function () {
              if (startDate.split("T")[0] == endDate.split("T")[0]) {
                return Highcharts.dateFormat("%H:%M", this.value);
              } else if (customPeriod === "week") {
                return "Uke" + " " + this.value;
              } else if (customPeriod === "month") {
                return Highcharts.dateFormat("%B", this.value);
              } else {
                return Highcharts.dateFormat("%e / %b", this.value);
              }
            },
          },
        },
        credits: { enabled: false },
        yAxis: {
          min: 0,
          title: {
            text: " ",
          },
        },
        series: series,
        tooltip: {
          formatter: function () {
            let tooltipText;
            let tooltipDate;
            let tooltipWeek;

            if (this.series.name.includes("uke")) {
              let date = moment(this.x);
              date.subtract(1, "week");
              tooltipDate = date.valueOf();
            } else if (this.series.name.includes("forrige")) {
              let date = moment(this.x);
              date.subtract(1, "year");
              tooltipDate = date.valueOf();
            } else {
              tooltipDate = this.x;
            }

            if (this.series.name.includes("uke")) {
              tooltipWeek = parseInt(this.x) - 1;
            } else {
              tooltipWeek = this.x;
            }

            if (startDate.split("T")[0] == endDate.split("T")[0]) {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%H:%M, %b %e", tooltipDate) +
                "</b><br/>" +
                this.series.name +
                " : " +
                formatNumber(this.y);
            } else if (customPeriod === "week") {
              tooltipText =
                "<b>" +
                "Uke" +
                " " +
                tooltipWeek +
                "</b><br/>" +
                this.series.name +
                " : " +
                formatNumber(this.y);
            } else if (customPeriod === "month") {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%B - %Y", tooltipDate) +
                "</b><br/>" +
                this.series.name +
                " : " +
                formatNumber(this.y);
            } else {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%A, %b %e, %Y", tooltipDate) +
                "</b><br/>" +
                this.series.name +
                " : " +
                formatNumber(this.y);
            }
            return tooltipText;
          },
        },
      });
    }

    function dateAndPeriodValidation(startedDate, endedDate) {
      let start = moment(startedDate);
      let end = moment(endedDate);

      if (start.isAfter(end)) {
        $("#start-date").val(startDate);
        $("#end-date").val(endDate);
        return alert("end date cannot be later than start date");
      } else {
        return true;
      }
    }

    function toggleCustomDatePicker() {
      let customDatePicker = $(".custom-date-picker");

      if (period === "custom") {
        customDatePicker.show();
      } else {
        customDatePicker.hide();
      }
    }

    function getVatPercent(
      startDate,
      endDate,
      period = null,
      supplierId = null,
      productGroupUniqueId = null,
      locationId = null,
      userId = null,
      platform = "all",
      customPeriod,
      topProductGroupUniqueId = 0,
      customerId,
      compareBy = null
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/statistic_vat/search",
        data: {
          startDate,
          endDate,
          period,
          supplierId,
          productGroupUniqueId,
          locationId,
          userId,
          platform,
          customPeriod,
          topProductGroupUniqueId,
          customerId,
          compareBy,
        },
      })
        .done(function (data) {
          if (data.currentList && data.currentList.length > 0) {
            let noVatCount = 0;
            let reduceVatCount = 0;
            let foodVatCount = 0;
            let regularVatCount = 0;
            $.map(data.currentList, function (item) {
              noVatCount += parseFloat(item.payment_data[0]);
              reduceVatCount += parseFloat(item.payment_data[1]);
              foodVatCount += parseFloat(item.payment_data[2]);
              regularVatCount += parseFloat(item.payment_data[3]);
            });
            $("#key-figure-mva-0").text(formatNumber(noVatCount));
            $("#key-figure-mva-12").text(formatNumber(reduceVatCount));
            $("#key-figure-mva-15").text(formatNumber(foodVatCount));
            $("#key-figure-mva-25").text(formatNumber(regularVatCount));
          } else {
            $("#key-figure-mva-0").text("0");
            $("#key-figure-mva-12").text("0");
            $("#key-figure-mva-15").text("0");
            $("#key-figure-mva-25").text("0");
          }

          if (startDate.split("T")[0] == endDate.split("T")[0]) {
            getHourGraph(data);
          } else if (customPeriod === "week") {
            getWeekGraph(data);
          } else if (customPeriod === "month") {
            getMonthGraph(data);
          } else {
            getDayGraph(data);
          }
          let aTags = $(".statistic-tab-group a");
          aTags.each(function () {
            let oldHref = $(this).attr("href").split("?")[0];
            let newHref =
              oldHref +
              `?filter_by=${period}&custom_period=${customPeriod}&prev_selected_period=${prevSelectedPeriod}&start_date=${startDate}&end_date=${endDate}`;
            $(this).prop("href", newHref);
          });
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function getHourGraph(data) {
      let hourArray = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24,
      ];

      let seriesData = [];
      $.map(hourArray, function (hour) {
        let found1 = $.grep(data.currentList, function (item) {
          return item.order_date === hour.toString();
        });

        let timestamp = moment
          .utc(startDate)
          .startOf("day")
          .add(hour, "hours")
          .valueOf();

        let hourData1 = [0, 0, 0, 0];
        let hourData2 = [0, 0, 0, 0];
        let hourData3 = [0, 0, 0, 0];
        if (found1 && found1.length > 0) {
          $.map(found1, function (item) {
            hourData1[0] += item.payment_data[0];
            hourData1[1] += item.payment_data[1];
            hourData1[2] += item.payment_data[2];
            hourData1[3] += item.payment_data[3];
          });
        }
        if (compareBy) {
          let found2 = $.grep(data.lastWeekList, function (item) {
            return item.order_date === hour.toString();
          });
          let found3 = $.grep(data.lastYearList, function (item) {
            return item.order_date === hour.toString();
          });
          if (found2 && found2.length > 0) {
            $.map(found2, function (item) {
              hourData2[0] += item.payment_data[0];
              hourData2[1] += item.payment_data[1];
              hourData2[2] += item.payment_data[2];
              hourData2[3] += item.payment_data[3];
            });
          }
          if (found3 && found3.length > 0) {
            $.map(found3, function (item) {
              hourData3[1] += item.payment_data[1];
              hourData3[0] += item.payment_data[0];
              hourData3[2] += item.payment_data[2];
              hourData3[3] += item.payment_data[3];
            });
          }
        }

        seriesData.push({
          name: timestamp,
          data: [hourData1, hourData2, hourData3],
        });
      });

      let interval = 3600 * 1000;
      eventLineChart(seriesData, interval);
    }

    function getDayGraph(data) {
      let seriesData = [];
      let startedDate = new Date(startDate.split("T")[0]);
      let endedDate = new Date(endDate.split("T")[0]);

      while (startedDate <= endedDate) {
        let dateStr = startedDate.toISOString().split("T")[0]; // Convert to YYYY-MM-DD format
        let timestamp = startedDate.getTime();
        let found1 = $.grep(data.currentList, function (item) {
          return item.order_date.split(" ")[0] === dateStr;
        });

        let dayData1 = [0, 0, 0, 0];
        let dayData2 = [0, 0, 0, 0];
        let dayData3 = [0, 0, 0, 0];
        if (found1 && found1.length > 0) {
          $.map(found1, function (item) {
            dayData1[0] += item.payment_data[0];
            dayData1[1] += item.payment_data[1];
            dayData1[2] += item.payment_data[2];
            dayData1[3] += item.payment_data[3];
          });
        }

        if (compareBy) {
          let found2 = $.grep(data.lastWeekList, function (item) {
            return (
              item.order_date.split(" ")[0] ===
              moment(dateStr).clone().subtract(1, "week").format("YYYY-MM-DD")
            );
          });
          let found3 = $.grep(data.lastYearList, function (item) {
            return (
              item.order_date.split(" ")[0] ===
              moment(dateStr).clone().subtract(1, "year").format("YYYY-MM-DD")
            );
          });
          if (found2 && found2.length > 0) {
            $.map(found2, function (item) {
              dayData2[0] += item.payment_data[0];
              dayData2[1] += item.payment_data[1];
              dayData2[2] += item.payment_data[2];
              dayData2[3] += item.payment_data[3];
            });
          }
          if (found3 && found3.length > 0) {
            $.map(found3, function (item) {
              dayData3[0] += item.payment_data[0];
              dayData3[1] += item.payment_data[1];
              dayData3[2] += item.payment_data[2];
              dayData3[3] += item.payment_data[3];
            });
          }
        }
        seriesData.push({
          name: timestamp,
          data: [dayData1, dayData2, dayData3],
        });
        startedDate.setDate(startedDate.getDate() + 1); // Move to the next day
      }

      // Sort the data by timestamp

      let interval = 24 * 3600 * 1000;
      eventLineChart(seriesData, interval);
    }
    function getWeekGraph(data) {
      let startDay = moment(startDate);
      let endDay = moment(endDate);
      let weekNumbers = [];

      while (startDay.isBefore(endDay)) {
        let weekNumber = startDay.isoWeek();
        if ($.inArray(weekNumber, weekNumbers) == -1) {
          weekNumbers.push(weekNumber);
        }
        startDay.add(1, "day");
      }
      let seriesData = [];
      $.map(weekNumbers, function (week_number) {
        let found1 = $.grep(data.currentList, function (item) {
          return item.order_date === week_number.toString();
        });
        let weekData1 = [0, 0, 0, 0];
        let weekData2 = [0, 0, 0, 0];
        let weekData3 = [0, 0, 0, 0];
        if (found1 && found1.length > 0) {
          $.map(found1, function (item) {
            weekData1[0] += item.payment_data[0];
            weekData1[1] += item.payment_data[1];
            weekData1[2] += item.payment_data[2];
            weekData1[3] += item.payment_data[3];
          });
        }

        if (compareBy) {
          let found2 = $.grep(data.lastWeekList, function (item) {
            return item.order_date === (week_number - 1).toString();
          });
          let found3 = $.grep(data.lastYearList, function (item) {
            return item.order_date === week_number.toString();
          });

          if (found2 && found2.length > 0) {
            $.map(found2, function (item) {
              weekData2[0] += item.payment_data[0];
              weekData2[1] += item.payment_data[1];
              weekData2[2] += item.payment_data[2];
              weekData2[3] += item.payment_data[3];
            });
          }

          if (found3 && found3.length > 0) {
            $.map(found3, function (item) {
              weekData3[0] += item.payment_data[0];
              weekData3[1] += item.payment_data[1];
              weekData3[2] += item.payment_data[2];
              weekData3[3] += item.payment_data[3];
            });
          }
        }
        seriesData.push({
          name: week_number,
          data: [weekData1, weekData2, weekData3],
        });
      });
      eventLineChart(seriesData);
    }

    function getMonthGraph(data) {
      let startDay = moment(startDate);
      let endDay = moment(endDate);
      let monthNumbers = [];

      while (startDay.isBefore(endDay)) {
        let monthNumber = startDay.month() + 1;
        monthNumbers.push(monthNumber);
        startDay.add(1, "month");
      }
      let seriesData = [];
      $.map(monthNumbers, function (month_number) {
        let found = $.grep(data.currentList, function (item) {
          return item.order_date === month_number.toString();
        });
        let monthNumberToDate =
          moment()
            .startOf("year")
            .add(month_number - 1, "month")
            .format("YYYY-MM-DD") + "T00:00";
        let monthData1 = [0, 0, 0, 0];
        let monthData2 = [0, 0, 0, 0];
        let monthData3 = [0, 0, 0, 0];
        if (found && found.length > 0) {
          $.map(found, function (item) {
            monthData1[0] += item.payment_data[0];
            monthData1[1] += item.payment_data[1];
            monthData1[2] += item.payment_data[2];
            monthData1[3] += item.payment_data[3];
          });
        }

        let formatedDate = new Date(monthNumberToDate);
        let timestamp = formatedDate.getTime();
        seriesData.push({
          name: timestamp,
          data: [monthData1, monthData2, monthData3],
        });
      });
      eventLineChart(seriesData);
    }

    getVatPercent(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      customPeriod,
      topProductGroupUniqueId,
      customerId,
      compareBy
    );
    toggleCustomDatePicker();
  }
});
