$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");

  if (
    p != "login" &&
    p != "user" &&
    p != "events_self_service" &&
    p != "order_confirmation"
  ) {
    if (window.ReactNativeWebView) {
      orderPrintInterval = setInterval(function () {
        getOrderToPrint();
      }, 5000);
    }
  }
});
