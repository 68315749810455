$(document).ready(function () {
  $("#period-title").text(moment().format("ddd - DD.MM.YYYY"));
  if (p === "statistic_turnover") {
    let isIncludingTableTurnOver = 0;
    let isIncludingCreditOrder = 0;

    $(document).on("change", "#customer-id", function (e) {
      e.preventDefault();

      customerId = $(this).val();
      isIncludingTableTurnOver = $("#include_table_turnover").is(":checked")
        ? 1
        : 0;
      getTurnoverSum(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        isIncludingTableTurnOver,
        isIncludingCreditOrder,
        topProductGroupUniqueId,
        customerId,
        compareBy
      );
    });

    if (deviceWidth < 768) {
      isMobile = true;
    } else if (deviceWidth >= 768 && deviceWidth < 992) {
      isTablet = true;
    } else {
      isDesktop = true;
    }

    $(document).on("change", "#included_credit_order", function () {
      isIncludingCreditOrder = $(this).is(":checked") ? 1 : 0;
      getTurnoverSum(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        isIncludingTableTurnOver,
        isIncludingCreditOrder,
        topProductGroupUniqueId,
        customerId,
        compareBy
      );
    });

    $(document).on("change", "#custom-period", function (e) {
      e.preventDefault();
      customPeriod = $(this).val();
      isIncludingTableTurnOver = $("#include_table_turnover").is(":checked")
        ? 1
        : 0;
      getTurnoverSum(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        isIncludingTableTurnOver,
        isIncludingCreditOrder,
        topProductGroupUniqueId,
        customerId,
        compareBy
      );
    });

    $(document).on("change", "#compare", function (e) {
      e.preventDefault();
      compareBy = $(this).val();
      let isIncludingTableTurnOver = $("#include_table_turnover").is(":checked")
        ? 1
        : 0;

      if (compareBy === "day-compare") {
        period = "day";
        $("#period-day").prop("checked", true);
      } else if (compareBy === "week-compare") {
        period = "week";
        $("#period-week").prop("checked", true);
      } else if (compareBy === "month-compare") {
        period = "month";
        $("#period-month").prop("checked", true);
      } else {
        compareBy = null;
      }
      $('input[type="radio"]:checked').trigger("change");
    });

    $(document).on("change", "#supplier-id", function (e) {
      e.preventDefault();
      supplierId = $(this).val();
      isIncludingTableTurnOver = $("#include_table_turnover").is(":checked")
        ? 1
        : 0;
      getTurnoverSum(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        isIncludingTableTurnOver,
        isIncludingCreditOrder,
        topProductGroupUniqueId,
        customerId,
        compareBy
      );
    });

    $(document).on("change", "#product-group-id", function (e) {
      e.preventDefault();
      $("#top-product-group-id").val(0);
      $("select[name=top-product-group-id]").select2({
        theme: "bootstrap4",
      });
      productGroupUniqueId = $(this).val();
      isIncludingTableTurnOver = $("#include_table_turnover").is(":checked")
        ? 1
        : 0;
      getTurnoverSum(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        isIncludingTableTurnOver,
        isIncludingCreditOrder,
        topProductGroupUniqueId,
        customerId,
        compareBy
      );
    });
    $(document).on("change", "#top-product-group-id", function (e) {
      e.preventDefault();
      $("#product-group-id").val(0);
      $("select[name=product-group-id]").select2({
        theme: "bootstrap4",
      });
      topProductGroupUniqueId = $(this).val();
      isIncludingTableTurnOver = $("#include_table_turnover").is(":checked")
        ? 1
        : 0;
      getTurnoverSum(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        isIncludingTableTurnOver,
        isIncludingCreditOrder,
        topProductGroupUniqueId,
        customerId,
        compareBy
      );
    });
    $(document).on("change", "#user-id", function (e) {
      e.preventDefault();
      userId = $(this).val();
      isIncludingTableTurnOver = $("#include_table_turnover").is(":checked")
        ? 1
        : 0;
      getTurnoverSum(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        isIncludingTableTurnOver,
        isIncludingCreditOrder,
        topProductGroupUniqueId,
        customerId,
        compareBy
      );
    });
    $(document).on("change", "#platform", function (e) {
      e.preventDefault();
      platform = $(this).val();

      if (platform === "self-service") {
        userId = 0;
        $("#user-id").val(userId);
        $("#user-id").prop("disabled", true);
      } else {
        $("#user-id").prop("disabled", false);
      }
      isIncludingTableTurnOver = $("#include_table_turnover").is(":checked")
        ? 1
        : 0;
      getTurnoverSum(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        isIncludingTableTurnOver,
        isIncludingCreditOrder,
        topProductGroupUniqueId,
        customerId,
        compareBy
      );
    });

    $(document).on("change", 'input[type="radio"]', function (e) {
      const newPeriod = $('input[type="radio"]:checked').val();
      period = newPeriod;
      prevSelectedPeriod = period !== "custom" ? period : prevSelectedPeriod;

      if (period === "day") {
        startDate = moment().format("YYYY-MM-DD") + "T00:00";
        endDate = moment().format("YYYY-MM-DD") + "T23:59";
        const currentDayFormatted = moment().format("ddd - DD.MM.YYYY");
        customPeriod = "time";
        $("#period-title").text(currentDayFormatted);
        if (compareBy && compareBy != 0) {
          compareBy = "day-compare";
          $("#compare").val("day-compare").trigger("change.select2");
        }
      } else if (period === "week") {
        startDate = moment().startOf("week").format("YYYY-MM-DD") + "T00:00";
        endDate = moment().endOf("week").format("YYYY-MM-DD") + "T23:59";
        const currentWeekNumber = moment().utc().isoWeek();
        customPeriod = "day";
        $(`#custom-period option[value="day"]`).prop("selected", true);
        $("#period-title").text("Uke nr" + " " + currentWeekNumber);
        if (compareBy && compareBy !== "0") {
          compareBy = "week-compare";
          $("#compare").val("week-compare").trigger("change.select2");
        }
      } else if (period === "month") {
        startDate = moment().startOf("month").format("YYYY-MM-DD") + "T00:00";
        endDate = moment().endOf("month").format("YYYY-MM-DD") + "T23:59";
        durationOfstartAndEndDate = 30;
        displayGraphDynamically(durationOfstartAndEndDate);
        if (isDesktop) {
          customPeriod = "day";
          $(`#custom-period option[value="time"]`).prop("selected", false);
          $(`#custom-period option[value="day"]`).prop("selected", true);
        } else {
          customPeriod = "week";
          $(`#custom-period option[value="day"]`).prop("selected", false);
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $(`#custom-period option[value="day"]`).prop("disabled", true);
        }
        const currentMonth = moment().format("MMMM");
        $("#period-title").text(currentMonth);
        if (compareBy && compareBy !== "0") {
          compareBy = "month-compare";
          $("#compare").val("month-compare").trigger("change.select2");
        }
      } else {
        $("#period-title").text("Custom");
        compareBy = null;
        $("#compare").val("0").trigger("change.select2");
        if (startDate == endDate) {
          $("#custom-period option[value='time']").prop("selected", true);
          $("#custom-period").prop("disabled", true);
        } else {
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period").prop("disabled", false);
        }
      }
      customPeriod = $("#custom-period").val();
      $("#start-date").val(startDate);
      $("#end-date").val(endDate);
      toggleCustomDatePicker();
      isIncludingTableTurnOver = $("#include_table_turnover").is(":checked")
        ? 1
        : 0;

      getTurnoverSum(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        isIncludingTableTurnOver,
        isIncludingCreditOrder,
        topProductGroupUniqueId,
        customerId,
        compareBy
      );
    });

    $(document).on("change", "#end-date", function (e) {
      e.preventDefault();
      let currentendDate = $(this).val();
      if (dateAndPeriodValidation(startDate, currentendDate)) {
        isDateChanged = true;
        endDate = currentendDate;
        period = $('input[name="period"]:checked').val();
        if (startDate.split("T")[0] == endDate.split("T")[0]) {
          $("#custom-period option[value='time']").prop("selected", true);
          $("#custom-period").prop("disabled", true);
        } else {
          durationOfstartAndEndDate = moment
            .utc(endDate)
            .diff(moment(startDate.split("T")[0]), "days");
          displayGraphDynamically(durationOfstartAndEndDate);

          $("#custom-period").prop("disabled", false);
        }

        isIncludingTableTurnOver = $("#include_table_turnover").is(":checked")
          ? 1
          : 0;
        getTurnoverSum(
          startDate,
          endDate,
          period,
          supplierId,
          productGroupUniqueId,
          locationId,
          userId,
          platform,
          customPeriod,
          isIncludingTableTurnOver,
          isIncludingCreditOrder,
          topProductGroupUniqueId,
          customerId,
          compareBy
        );
        prevSelectedPeriod = "custom";
        $("#period-title").text(
          moment(startDate).format("DD-MM-YYYY") +
            " : " +
            moment(endDate).format("DD-MM-YYYY")
        );
      }
    });

    $(document).on("change", "#start-date", function (e) {
      e.preventDefault();
      let choosenDate = $(this).val();

      if (dateAndPeriodValidation(choosenDate, endDate)) {
        startDate = choosenDate;
        isDateChanged = true;
        period = $('input[name="period"]:checked').val();
        if (startDate == endDate) {
          $("#custom-period option[value='time']").prop("selected", true);
          $("#custom-period").prop("disabled", true);
        } else {
          durationOfstartAndEndDate = moment
            .utc(endDate.split("T")[0])
            .diff(moment(startDate), "days");
          displayGraphDynamically(durationOfstartAndEndDate);
          $("#custom-period").prop("disabled", false);
        }
        isIncludingTableTurnOver = $("#include_table_turnover").is(":checked")
          ? 1
          : 0;
        getTurnoverSum(
          startDate,
          endDate,
          period,
          supplierId,
          productGroupUniqueId,
          locationId,
          userId,
          platform,
          customPeriod,
          isIncludingTableTurnOver,
          isIncludingCreditOrder,
          topProductGroupUniqueId,
          customerId,
          compareBy
        );
        prevSelectedPeriod = "custom";
        $("#period-title").text(
          moment(startDate).format("DD-MM-YYYY") +
            " : " +
            moment(endDate).format("DD-MM-YYYY")
        );
      }
    });

    $(document).on("click", "#previous-button", function (e) {
      e.preventDefault();
      let periodTitle = "";
      if (
        period === "day" ||
        (prevSelectedPeriod === "day" && !isDateChanged && period === "custom")
      ) {
        startDate =
          moment.utc(startDate).subtract(1, "day").format("YYYY-MM-DD") +
          "T00:00";
        endDate =
          moment.utc(endDate).subtract(1, "day").format("YYYY-MM-DD") +
          "T23:59";
        periodTitle = moment(startDate).format("ddd - DD.MM.YYYY");
      }
      if (
        period === "week" ||
        (prevSelectedPeriod === "week" && !isDateChanged && period === "custom")
      ) {
        startDate =
          moment.utc(startDate).subtract(1, "week").format("YYYY-MM-DD") +
          "T00:00";
        endDate =
          moment(endDate).subtract(1, "week").format("YYYY-MM-DD") + "T23:59";
        const currentWeekNumber = moment(startDate).isoWeek();
        periodTitle = "Uke nr" + " " + currentWeekNumber;
      }

      if (
        period === "month" ||
        (prevSelectedPeriod === "month" &&
          !isDateChanged &&
          period === "custom")
      ) {
        startDate =
          moment.utc(startDate).subtract(1, "month").format("YYYY-MM-DD") +
          "T00:00";
        endDate =
          moment(endDate).subtract(1, "month").format("YYYY-MM-DD") + "T23:59";
        periodTitle = moment(startDate).format("MMMM");
      }
      if (period === "custom" && prevSelectedPeriod === "custom") {
        let previousStartDate = moment(startDate);
        let previousEndDate = moment(endDate);

        endDate =
          moment(startDate).subtract(1, "day").format("YYYY-MM-DD") + "T23:59";

        durationOfstartAndEndDate = previousEndDate.diff(
          previousStartDate,
          "days"
        );

        startDate =
          moment
            .utc(endDate)
            .subtract(durationOfstartAndEndDate, "days")
            .format("YYYY-MM-DD") + "T00:00";
        periodTitle =
          moment(startDate).format("DD-MM-YYYY") +
          " : " +
          moment(endDate).format("DD-MM-YYYY");
      }
      $("#start-date").val(startDate);
      $("#end-date").val(endDate);
      $("#period-title").text(periodTitle);
      isIncludingTableTurnOver = $("#include_table_turnover").is(":checked")
        ? 1
        : 0;
      getTurnoverSum(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        isIncludingTableTurnOver,
        isIncludingCreditOrder,
        topProductGroupUniqueId,
        customerId,
        compareBy
      );
    });

    $(document).on("click", "#next-button", function (e) {
      e.preventDefault();
      let periodTitle = "";
      if (
        period === "day" ||
        (prevSelectedPeriod === "day" && !isDateChanged && period === "custom")
      ) {
        startDate =
          moment.utc(startDate).add(1, "day").format("YYYY-MM-DD") + "T00:00";
        endDate =
          moment.utc(endDate).add(1, "day").format("YYYY-MM-DD") + "T23:59";
        periodTitle = moment(startDate).format("ddd - DD.MM.YYYY");
      }
      if (
        period === "week" ||
        (prevSelectedPeriod === "week" && !isDateChanged && period === "custom")
      ) {
        startDate =
          moment(startDate).add(1, "week").format("YYYY-MM-DD") + "T00:00";
        endDate =
          moment(endDate).add(1, "week").format("YYYY-MM-DD") + "T23:59";
        const currentWeekNumber = moment(startDate).isoWeek();
        periodTitle = "Uke nr" + " " + currentWeekNumber;
      }

      if (
        period === "month" ||
        (prevSelectedPeriod === "month" &&
          !isDateChanged &&
          period === "custom")
      ) {
        startDate =
          moment(startDate).add(1, "month").format("YYYY-MM-DD") + "T00:00";
        endDate =
          moment(endDate).add(1, "month").format("YYYY-MM-DD") + "T23:59";
        periodTitle = moment(startDate).format("MMMM");
      }

      if (period === "custom" && prevSelectedPeriod === "custom") {
        let previousStartDate = moment(startDate);
        let previousEndDate = moment(endDate);

        startDate =
          moment(endDate).add(1, "day").format("YYYY-MM-DD") + "T00:00";

        durationOfstartAndEndDate = previousEndDate.diff(
          previousStartDate,
          "days"
        );

        endDate =
          moment
            .utc(startDate)
            .add(durationOfstartAndEndDate, "days")
            .format("YYYY-MM-DD") + "T23:59";
        periodTitle =
          moment(startDate).format("DD-MM-YYYY") +
          " : " +
          moment(endDate).format("DD-MM-YYYY");
      }
      $("#start-date").val(startDate);
      $("#end-date").val(endDate);
      $("#period-title").text(periodTitle);
      isIncludingTableTurnOver = $("#include_table_turnover").is(":checked")
        ? 1
        : 0;
      getTurnoverSum(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        isIncludingTableTurnOver,
        isIncludingCreditOrder,
        topProductGroupUniqueId,
        customerId,
        compareBy
      );
    });

    function eventLineChart(
      categoryArray,
      seriesData1,
      seriesData2,
      seriesData3,
      interval = null
    ) {
      // Render the chart
      Highcharts.chart("event-line-graph", {
        chart: {
          type: "column", // Column chart
        },
        title: {
          text: null,
        },
        exporting: { enabled: false },
        plotOptions: {
          series: {
            // Column charts don't need a lineWidth option
          },
        },
        xAxis: {
          categories: categoryArray,
          labels: {
            formatter: function () {
              if (startDate.split("T")[0] === endDate.split("T")[0]) {
                return this.value;
              } else if (customPeriod === "week") {
                return "Uke " + this.value;
              } else if (customPeriod === "month") {
                return Highcharts.dateFormat(
                  "%B",
                  new Date(this.value).getTime()
                );
              } else {
                return Highcharts.dateFormat(
                  "%e/%b",
                  new Date(this.value).getTime()
                );
              }
            },
          },
        },
        credits: { enabled: false },
        yAxis: {
          title: {
            text: "Omtsetning (ink mva)",
          },
        },
        series:
          seriesData2.length === 0 && seriesData3.length === 0
            ? [
                {
                  name: "Omtsetning",
                  data: seriesData1.map((value, index) => [
                    categoryArray[index],
                    value,
                  ]),
                },
              ]
            : [
                {
                  name: "Omtsetning",
                  data: seriesData1.map((value, index) => [
                    categoryArray[index],
                    value,
                  ]),
                },
                {
                  name: "Omtsetning forrige uke",
                  data: seriesData2.map((value, index) => [
                    categoryArray[index],
                    value,
                  ]),
                },
                {
                  name: "Omtsetning forrige år",
                  data: seriesData3.map((value, index) => [
                    categoryArray[index],
                    value,
                  ]),
                },
              ],
        tooltip: {
          formatter: function () {
            let tooltipText;
            if (
              period === "day" &&
              startDate.split("T")[0] === endDate.split("T")[0]
            ) {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%H:%M, %b %e", this.x) +
                "</b><br/>" +
                "Omtsetning: " +
                Highcharts.numberFormat(this.y, 2);
            } else if (customPeriod === "week") {
              tooltipText =
                "<b>" +
                "Uke " +
                this.x +
                "</b><br/>" +
                "Omtsetning: " +
                Highcharts.numberFormat(this.y, 2);
            } else if (customPeriod === "month") {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%B - %Y", this.x) +
                "</b><br/>" +
                "Omtsetning: " +
                Highcharts.numberFormat(this.y, 2);
            } else {
              tooltipText =
                "<b>" +
                Highcharts.dateFormat("%A, %b %e, %Y", this.x) +
                "</b><br/>" +
                "Omtsetning: " +
                Highcharts.numberFormat(this.y, 2);
            }
            return tooltipText;
          },
        },
      });
    }

    function dateAndPeriodValidation(startedDate, endedDate) {
      let start = moment(startedDate);
      let end = moment(endedDate);

      if (start.isAfter(end)) {
        $("#start-date").val(startDate);
        $("#end-date").val(endDate);
        return alert("end date cannot be later than start date");
      } else {
        return true;
      }
    }

    function getTurnoverSum(
      startDate,
      endDate,
      period = null,
      supplierId = null,
      productGroupUniqueId = null,
      locationId = null,
      userId = null,
      platform = "all",
      customPeriod,
      isIncludingTableTurnOver = 0,
      isIncludingCreditOrder = 0,
      topProductGroupUniqueId = 0,
      customerId = 0,
      compareBy = null
    ) {
      $.ajax({
        type: "POST",
        url: "/ajax/statistic_turnover/search",
        data: {
          startDate,
          endDate,
          period,
          supplierId,
          productGroupUniqueId,
          locationId,
          userId,
          platform,
          customPeriod,
          isIncludingTableTurnOver,
          isIncludingCreditOrder,
          topProductGroupUniqueId,
          customerId,
          compareBy,
        },
      })
        .done(function (data) {
          if (data.currentList && data.currentList.length > 0) {
            let totalPriceExVat = 0;
            let totalPriceIncVat = 0;

            $.each(data.currentList, function (index, item) {
              totalPriceExVat += item.price_ex_vat
                ? parseFloat(item.price_ex_vat)
                : 0;
              totalPriceIncVat += item.price_inc_vat
                ? parseFloat(item.price_inc_vat)
                : 0;
            });
            $(".turnover-ex-vat").text(formatNumber(totalPriceExVat));
            $(".turnover-inc-vat").text(formatNumber(totalPriceIncVat));
          } else {
            $(".turnover-ex-vat").text("0");
            $(".turnover-inc-vat").text("0");
          }

          if (startDate.split("T")[0] == endDate.split("T")[0]) {
            getHourGraph(data);
          } else if (customPeriod === "week") {
            getWeekGraph(data);
          } else if (customPeriod === "month") {
            getMonthGraph(data);
          } else {
            getDayGraph(data);
          }

          let aTags = $(".statistic-tab-group a");
          aTags.each(function () {
            let oldHref = $(this).attr("href").split("?")[0];
            let newHref =
              oldHref +
              `?filter_by=${period}&custom_period=${customPeriod}&prev_selected_period=${prevSelectedPeriod}&start_date=${startDate}&end_date=${endDate}`;
            $(this).prop("href", newHref);
          });
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function toggleCustomDatePicker() {
      let customDatePicker = $(".custom-date-picker");

      if (period === "custom") {
        customDatePicker.show();
      } else {
        customDatePicker.hide();
      }
    }
    function getHourGraph(data) {
      let hourArray = Array.from({ length: 25 }, (_, index) => index);

      let seriesData1 = [];
      let seriesData2 = [];
      let seriesData3 = [];
      let xAxisArray = [];

      $.map(hourArray, function (hour) {
        let foundedCurrent = $.grep(data.currentList, function (item) {
          return item.order_date === hour.toString();
        });

        let totalTurnOver1 = 0;

        if (foundedCurrent.length > 0) {
          $.map(foundedCurrent, function (item) {
            totalTurnOver1 += item.price_inc_vat;
          });
        }

        if (compareBy && compareBy != 0) {
          let foundedLastWeek = $.grep(data.lastWeekList, function (item) {
            return item.order_date === hour.toString();
          });
          let foundedLastYear = $.grep(data.lastYearList, function (item) {
            return item.order_date === hour.toString();
          });

          let totalTurnOver2 = 0;
          let totalTurnOver3 = 0;

          if (foundedLastWeek.length > 0) {
            $.map(foundedLastWeek, function (item) {
              totalTurnOver2 += item.price_inc_vat;
            });
          }
          if (foundedLastYear.length > 0) {
            $.map(foundedLastYear, function (item) {
              totalTurnOver3 += item.price_inc_vat;
            });
          }
          seriesData2.push(totalTurnOver2);
          seriesData3.push(totalTurnOver3);
        }

        seriesData1.push(totalTurnOver1);

        xAxisArray.push(
          (hour.toString().length === 1
            ? "0" + hour.toString()
            : hour.toString()) + ":00"
        );
      });

      let interval = 3600 * 1000;
      eventLineChart(
        xAxisArray,
        seriesData1,
        seriesData2,
        seriesData3,
        interval
      );
    }

    function getDayGraph(data) {
      let dailyTotals = {};
      let dailyTotals2 = {};
      let dailyTotals3 = {};
      data.currentList.forEach(function (item) {
        if (Array.isArray(item) && item.length === 0) {
          // Skip empty arrays
          return;
        }
        let date = item.order_date.split(" ")[0];
        if (dailyTotals[date]) {
          dailyTotals[date] += parseFloat(item.price_inc_vat);
        } else {
          dailyTotals[date] = parseFloat(item.price_inc_vat);
        }
      });

      // Prepare data for Highcharts
      let dateArray = [];
      let seriesData1 = [];
      let seriesData2 = [];
      let seriesData3 = [];
      let startedDate = new Date(startDate.split("T")[0]);

      if (compareBy && compareBy !== "0") {
        data.lastWeekList.forEach(function (item) {
          let lastWeekDate = item.order_date.split(" ")[0];

          if (dailyTotals2[lastWeekDate]) {
            dailyTotals2[lastWeekDate] += parseFloat(item.price_inc_vat);
          } else {
            dailyTotals2[lastWeekDate] = parseFloat(item.price_inc_vat);
          }
        });
        data.lastYearList.forEach(function (item) {
          let lastYearDate = item.order_date.split(" ")[0];
          if (dailyTotals3[lastYearDate]) {
            dailyTotals3[lastYearDate] += parseFloat(item.price_inc_vat);
          } else {
            dailyTotals3[lastYearDate] = parseFloat(item.price_inc_vat);
          }
        });
      }

      let endedDate = new Date(endDate.split("T")[0]);
      endedDate.setHours(23, 59, 59, 0);

      while (startedDate <= endedDate) {
        let dateStr = startedDate.toISOString().split("T")[0]; // Convert to YYYY-MM-DD format
        let total1 = dailyTotals[dateStr] || 0; // Use total from dailyTotals or 0 if not available

        dateArray.push(dateStr);
        seriesData1.push(total1);

        startedDate.setDate(startedDate.getDate() + 1); // Move to the next day
      }

      if (compareBy) {
        const newStartDate = moment(startDate.split("T")[0]);
        const newEndDate = moment(endDate.split("T")[0]);

        // Get the same date for the previous week, month, and year
        let lastWeekStartDate = newStartDate
          .clone()
          .subtract(1, "week")
          .format("YYYY-MM-DD");
        let lastMonthStartDate = newStartDate
          .clone()
          .subtract(1, "month")
          .format("YYYY-MM-DD");

        let lastYearStartDate = newStartDate
          .clone()
          .subtract(1, "year")
          .format("YYYY-MM-DD");

        let lastYearEndDate = newEndDate
          .clone()
          .subtract(1, "year")
          .format("YYYY-MM-DD");
        let lastWeekEndDate = newEndDate
          .clone()
          .subtract(1, "week")
          .format("YYYY-MM-DD");
        let lastMonthEndDate = newEndDate
          .clone()
          .subtract(1, "month")
          .format("YYYY-MM-DD");

        if (
          (period === "week" || period === "month") &&
          customPeriod === "day"
        ) {
          while (lastWeekStartDate <= lastWeekEndDate) {
            let total2 = dailyTotals2[lastWeekStartDate] || 0; // Use total from dailyTotals or 0 if not available

            seriesData2.push(total2);

            lastWeekStartDate = moment(lastWeekStartDate)
              .add(1, "day")
              .format("YYYY-MM-DD");
          }

          while (lastYearStartDate <= lastYearEndDate) {
            let total3 = dailyTotals3[lastYearStartDate] || 0; // Use total from dailyTotals or 0 if not available

            seriesData3.push(total3);

            lastYearStartDate = moment(lastYearStartDate)
              .add(1, "day")
              .format("YYYY-MM-DD");
          }
        }
      }

      let interval = 24 * 3600 * 1000;
      eventLineChart(
        dateArray,
        seriesData1,
        seriesData2,
        seriesData3,
        interval
      );
    }

    function getWeekGraph(data) {
      let startDay = moment(startDate);
      let endDay = moment(endDate);
      let weekNumbers = [];

      while (startDay.isBefore(endDay)) {
        let weekNumber = startDay.isoWeek();
        weekNumbers.push(weekNumber);
        startDay.add(1, "day");
      }
      let seriesData1 = [];
      let seriesData2 = [];
      let seriesData3 = [];
      $.map(weekNumbers, function (week_number) {
        let found = $.grep(data.currentList, function (item) {
          return item.order_date === week_number.toString();
        });

        let totalWeeklyTurnover1 = 0;
        let totalWeeklyTurnover2 = 0;
        let totalWeeklyTurnover3 = 0;
        if (found.length > 0) {
          $.map(found, function (item) {
            totalWeeklyTurnover1 += item.price_inc_vat;
          });
        }
        seriesData1.push(totalWeeklyTurnover1);

        if (compareBy) {
          let found2 = $.grep(data.lastWeekList, function (item) {
            return item.order_date === week_number.toString();
          });
          if (found2.length > 0) {
            $.map(found2, function (item) {
              totalWeeklyTurnover2 += item.price_inc_vat;
            });
            seriesData2.push(totalWeeklyTurnover1);
          }
          let found3 = $.grep(data.lastYearList, function (item) {
            return item.order_date === week_number.toString();
          });
          if (found3.length > 0) {
            $.map(found3, function (item) {
              totalWeeklyTurnover3 += item.price_inc_vat;
            });
            seriesData3.push(totalWeeklyTurnover3);
          }
        }
      });

      eventLineChart(weekNumbers, seriesData1, seriesData2, seriesData3);
    }

    function getMonthGraph(data) {
      data.currentList.sort(
        (a, b) => new Date(a.order_confirmed) - new Date(b.order_confirmed)
      );
      let startDay = moment(startDate);
      let endDay = moment(endDate);
      let monthNumbers = [];

      while (startDay.isBefore(endDay)) {
        let monthNumber = startDay.month() + 1;
        monthNumbers.push(monthNumber);
        startDay.add(1, "month");
      }
      let seriesData1 = [];
      let seriesData2 = [];
      let seriesData3 = [];
      let monthArray = [];
      $.map(monthNumbers, function (month_number) {
        let found = $.grep(data.currentList, function (item) {
          return item.order_date === month_number.toString();
        });
        let monthNumberToDate = moment()
          .startOf("year")
          .add(month_number - 1, "month")
          .format("YYYY-MM-DD");
        let totalMonthlyTurnOver = 0;
        if (found.length > 0) {
          $.map(found, function (item) {
            totalMonthlyTurnOver += item.price_inc_vat;
          });
        }
        monthArray.push(monthNumberToDate);
        seriesData1.push(totalMonthlyTurnOver);
      });

      eventLineChart(monthArray, seriesData1, seriesData2, seriesData3);
    }

    $(document).on("change", "#include_table_turnover", function () {
      isIncludingTableTurnOver = $(this).is(":checked") ? 1 : 0;
      getTurnoverSum(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        isIncludingTableTurnOver,
        isIncludingCreditOrder,
        customerId,
        compareBy
      );
    });

    function displayGraphDynamically(durationOfstartAndEndDate) {
      if (isMobile) {
        if (durationOfstartAndEndDate <= 7) {
          customPeriod = "day";
          $("#custom-period option[value='day']").prop("disabled", false);
          $("#custom-period option[value='day']").prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").removeClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else if (
          durationOfstartAndEndDate >= 8 &&
          durationOfstartAndEndDate < 49
        ) {
          //week
          customPeriod = "week";
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else {
          //month
          customPeriod = "month";
          $(`#custom-period option[value="week"]`).prop("selected", false);
          $(`#custom-period option[value="month"]`).prop("selected", true);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='week']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").addClass("disabledOption");
        }
      } else if (isTablet) {
        if (durationOfstartAndEndDate <= 14) {
          //day
          customPeriod = "day";
          $("#custom-period option[value='day']").prop("disabled", false);
          $("#custom-period option[value='day']").prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").removeClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else if (
          durationOfstartAndEndDate >= 15 &&
          durationOfstartAndEndDate < 98
        ) {
          //week
          customPeriod = "week";
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else {
          //month
          customPeriod = "month";
          $(`#custom-period option[value="week"]`).prop("selected", false);
          $(`#custom-period option[value="month"]`).prop("selected", true);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='week']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").addClass("disabledOption");
        }
      } else {
        if (durationOfstartAndEndDate <= 31) {
          //day
          customPeriod = "day";
          $("#custom-period option[value='day']").prop("disabled", false);
          $("#custom-period option[value='day']").prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").removeClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else if (
          durationOfstartAndEndDate >= 32 &&
          durationOfstartAndEndDate < 217
        ) {
          //week
          customPeriod = "week";
          $(`#custom-period option[value="week"]`).prop("selected", true);
          $("#custom-period option[value='week']").prop("disabled", false);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").removeClass(
            "disabledOption"
          );
        } else {
          //month
          customPeriod = "month";
          $(`#custom-period option[value="week"]`).prop("selected", false);
          $(`#custom-period option[value="month"]`).prop("selected", true);
          $("#custom-period option[value='time']").prop("disabled", true);
          $("#custom-period option[value='day']").prop("disabled", true);
          $("#custom-period option[value='week']").prop("disabled", true);
          $("#custom-period option[value='time']").addClass("disabledOption");
          $("#custom-period option[value='day']").addClass("disabledOption");
          $("#custom-period option[value='week']").addClass("disabledOption");
        }
      }
    }
    getTurnoverSum(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      customPeriod,
      isIncludingTableTurnOver,
      isIncludingCreditOrder,
      topProductGroupUniqueId,
      customerId,
      compareBy
    );
    toggleCustomDatePicker();
  }
});
