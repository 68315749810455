$(document).ready(function () {
  let p = $("body").attr("p");
  const url = $("#automat-link").attr("href") || "";

  if (p === "select_mode") {
    const selectModeUrl = location.href;
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ action: "save_select_mode_url", data: selectModeUrl })
      );
    }
  }

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ action: "check_automat_url" })
    );

    document.addEventListener("message", function (event) {
      saveSelectModeUrls(event, url);
    });
    window.addEventListener("message", function (event) {
      saveSelectModeUrls(event, url);
    });
  }

  let clickCount = 0;
  let clickTimeout;

  $(".navbar-brand").on("click", function (e) {
    e.preventDefault();
    if (window.ReactNativeWebView) {
      clickCount++;

      if (clickCount === 3) {
        clearTimeout(clickTimeout);
        clickCount = 0;
        const href = $(this).attr("data-triple-tap-href");
        location.href = href;
      } else {
        clearTimeout(clickTimeout);
        const href = $(this).attr("data-single-tap-href");
        clickTimeout = setTimeout(function () {
          if (clickCount === 1) {
            location.href = href;
          }
          clickCount = 0;
        }, 300);
      }
    } else {
      const href = $(this).attr("data-single-tap-href");
      location.href = href;
    }
  });
});

function saveSelectModeUrls(event, url) {
  const { automatUrl, selectModeUrl } = JSON.parse(event.data);

  if (automatUrl && automatUrl !== "null") {
    $("#automat-link").attr("href", automatUrl);
  } else if (window.ReactNativeWebView && !automatUrl) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ action: "save_automat_url", data: url })
    );
  }

  if (selectModeUrl && selectModeUrl !== "null") {
    $(".navbar-brand").attr("data-triple-tap-href", selectModeUrl);
  }
}
