$(document).ready(function () {
  let p = $("body").attr("p");
  let locationId = $("body").attr("event_id");
  let selectedTemplate;
  if (p === "template_import") {
    let templateArray = [];
    $(document).on(
      "change",
      "#product-group-unique-template-input",
      function () {
        const file = this.files[0];
      $(this).val("");
      $("#imported-data-tables").empty();

      if (!file) {
        alert("Vennligst velg en fil!");
        return;
      }

      const validTypes = [
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      const validExtensions = ["csv", "xlsx"];

      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileType = file.type;

      if (
        !validExtensions.includes(fileExtension) ||
        !validTypes.includes(fileType)
      ) {
        alert(
          "Ugyldig filtype. Vennligst last opp en gyldig .csv- eller .xlsx-fil."
        );
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("location_id", locationId);;
        
      $.ajax({
        url: "/api/v1/private/product-group-unique/import-product-group",
        type: "POST",
        data: formData,
        contentType: false,
        processData: false,
        success: function (response) {
          if (response.html) {
            popupAlert();
            $("#imported-data-tables").empty();
            $("#imported-data-tables").html(response.html);
          }
          if (response.exisitingProductGroupList) {
            templateArray = response.exisitingProductGroupList;
          }
        },
        error: function (xhr, status, error) {
          let errorMessage = "File upload failed";
          if (xhr.responseJSON && xhr.responseJSON.message) {
            errorMessage = xhr.responseJSON.message;
          } else if (xhr.responseText) {
            errorMessage = xhr.responseText;
          }
          alert(errorMessage);
          console.error(errorMessage);
        },
      });
      }
    );

    $(document).on("click", "#overwrite-product-group-confirm", function () {
      $.ajax({
        url: "/api/v1/private/product-group-unique/overwrite-product-group",
        type: "POST",
        data: { data: templateArray, location_id: locationId },
        success: function (response) {
          if (response.success) {
            popupAlert();
            $("#product-group-overwrite-button").attr("hidden", true);
            $("#overwriteProductGroupTemplateConfirmationModal").modal("hide");
          }
        },
        error: function (xhr, status, error) {
          $("#overwriteProductGroupTemplateConfirmationModal").modal("hide");
          alert("Kunne ikke overskrive varegrupper");
          console.error(error);
        },
      });
    });

    function downloadCSV(type) {
      const url = `/api/v1/private/product-group-unique/download-template?type=${type}`;
      window.location.href = url;
    }

    $(".product-group-unique-template-download").on("click", function () {
      const type = $(this).data("type");
      downloadCSV(type);
    });
  }
});
