$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");

  if (p == "product_group_account_plan" || p == "general_account_plan") {
    let filterStartDate = $("#start-date").val();
    let filterEndDate = $("#end-date").val();

    $(document).on("click", ".account-plan-download", function (e) {
      e.preventDefault();
      let startDate = $(this).attr("data-start-date");
      let endDate = $(this).attr("data-end-date");
      const url = `/api/v1/private/account_plan/download_account_plan?start_date=${startDate}&end_date=${endDate}`;
      window.location.href = url;
    });

    //start-end date filter
    $(document).on("change", "#start-date", function (e) {
      filterStartDate = $(this).val();
      filterEndDate = $("#end-date").val();
      if (checkDateValidation(filterStartDate, filterEndDate)) {
        getAccountPlanDownloadList(filterStartDate, filterEndDate);
      } else {
        $(this).focus();
        return;
      }
    });

    $(document).on("change", "#end-date", function (e) {
      filterEndDate = $(this).val();
      filterStartDate = $("#start-date").val();
      if (checkDateValidation(filterStartDate, filterEndDate)) {
        getAccountPlanDownloadList(filterStartDate, filterEndDate);
      } else {
        $(this).focus();
        return;
      }
    });

    function checkDateValidation(filterStartDate, filterEndDate) {
      if (!filterStartDate || !filterEndDate) {
        alert("Både startdato og sluttdato er obligatorisk.");
        return false;
      }

      if (new Date(filterEndDate) < new Date(filterStartDate)) {
        alert("Sluttdato kan ikke være før startdato.");
        return false;
      }

      return true;
    }

    let page = 1;

    $(document).on("click", ".pagination a", function (e) {
      e.preventDefault();
      filterStartDate = $("#start-date").val();
      filterEndDate = $("#end-date").val();
      page = parseInt($(this).attr("href").substring(6));
      getAccountPlanDownloadList(filterStartDate, filterEndDate, page);
    });

    function getAccountPlanDownloadList(startDate, endDate, page) {
      $.ajax({
        type: "POST",
        url: "/api/v1/private/account_plan/search",
        data: {
          start_date: startDate,
          end_date: endDate,
          page,
        },
      })
        .done(function (data) {
          if (data.html && data.html !== null && data.html !== "") {
            $("#excel-download-list-contanier").empty();
            $("#excel-download-list-contanier").html(data.html);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }

          if (
            data.responseJSON.message !== undefined &&
            data.responseJSON.message !== null
          ) {
            $("#inventory-stock-history-response").empty();
            $("#inventory-stock-history-response").append(
              data.responseJSON.message
            );
          }
        });
    }
  }
});
