$(document).ready(function () {
  let p = $("body").attr("p");
  const locationId = $("body").attr("event_id");

  if (p === "template_import") {
    let existingData = [];

    $(document).on("change", "#import-product", function () {
      const file = this.files[0];
      $(this).val("");
      $("#imported-data-tables").empty();

      if (!file) {
        alert("Vennligst velg en fil!");
        return;
      }

      const validTypes = [
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      const validExtensions = ["csv", "xlsx"];

      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileType = file.type;

      if (
        !validExtensions.includes(fileExtension) ||
        !validTypes.includes(fileType)
      ) {
        alert(
          "Ugyldig filtype. Vennligst last opp en gyldig .csv- eller .xlsx-fil."
        );
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("location_id", locationId);

      $.ajax({
        url: "/api/v1/private/products/import-product",
        type: "POST",
        data: formData,
        contentType: false,
        processData: false,
        success: function (response) {
          if (response.html) {
            popupAlert();
            $("#imported-data-tables").empty();
            $("#imported-data-tables").html(response.html);
          }

          if (
            response.existing_products &&
            response.existing_products.length > 0
          ) {
            existingData = response.existing_products;
          }
        },
        error: function (xhr, status, error) {
          let errorMessage = "De importerte dataene inneholder feil. Pass på at du ikke bruker komma ',' som tallperiode og riktig mal før du prøver å importere igjen.";
          if (xhr.responseJSON && xhr.responseJSON.message) {
            errorMessage = xhr.responseJSON.message;
          } else if (xhr.responseText) {
            errorMessage = xhr.responseText;
          }
          alert(errorMessage);
          console.error(errorMessage);
        },
      });
    });

    $(document).on("click", "#override-confirm", function () {
      const button = $(this);
      const buttonText = $(this).text();
      $(this).empty();
      $(this).append('<div class="loader"></div>');

      const payload = {
        location_id: locationId,
        data: existingData,
      };

      const formData = new FormData();

      // Append each property
      formData.append("location_id", payload.location_id); // Append location ID
      formData.append("data", JSON.stringify(payload.data)); // Serialize array as JSON

      $.ajax({
        url: "/api/v1/private/products/override-products",
        type: "POST",
        data: formData,
        contentType: false,
        processData: false,
        success: function (response) {
          popupAlert();
          $("#overwrite-footer").remove();
          $("#importTempalteConfirmationModal").modal("hide");
        },
        error: function (xhr, status, error) {
          button.text(buttonText);
          $("#importTempalteConfirmationModal").modal("hide");
          alert("Failed to override data");
          console.error(error);
        },
      });
    });

    function downloadCSV(type) {
      const url = `/api/v1/private/products/download-template?type=${type}`;
      window.location.href = url;
    }

    $(".product-template-download").on("click", function () {
      const type = $(this).data("type");
      downloadCSV(type);
    });
  }
});
