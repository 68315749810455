$(document).ready(function () {
  const event_id = $("body").attr("event_id");

  $(document).on(
    "change",
    "#import-private-customer,#import-company-customer",
    function () {
      const file = this.files[0];
      $(this).val("");
      $("#imported-data-tables").empty();
      $("#customer-table").empty();

      if (!file) {
        alert("Vennligst velg en fil!");
        return;
      }

      const validTypes = [
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      const validExtensions = ["csv", "xlsx"];

      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileType = file.type;

      if (
        !validExtensions.includes(fileExtension) ||
        !validTypes.includes(fileType)
      ) {
        alert(
          "Ugyldig filtype. Vennligst last opp en gyldig .csv- eller .xlsx-fil."
        );
        return;
      }

      const customerType =
        this.id === "import-private-customer" ? "private" : "company";

      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", customerType);
      formData.append("location_id", event_id);

      $.ajax({
        url: "/api/v1/private/customers/import",
        type: "POST",
        data: formData,
        contentType: false,
        processData: false,
        success: function (response) {
          if (response.html !== undefined && response.html !== null) {
            $("#imported-data-tables").empty();
            $("#imported-data-tables").html(response.html);
          }

          if (
            response.success !== undefined &&
            response.success !== null &&
            response.success !== ""
          ) {
            popupAlert();
          }
        },
        error: function (xhr, status, error) {
          let errorMessage = "File upload failed";
          if (xhr.responseJSON && xhr.responseJSON.message) {
            errorMessage = xhr.responseJSON.message;
          } else if (xhr.responseText) {
            errorMessage = xhr.responseText;
          }
          alert(errorMessage);
          console.error(errorMessage);
        },
      });
    }
  );

  function downloadCSV(type, fileType) {
    const url = `/api/v1/private/customers/download-template?type=${type}&fileType=${fileType}`;
    window.location.href = url;
  }

  $(".download-private-template").on("click", function () {
    const fileType = $(this).data("type");
    downloadCSV("private", fileType);
  });

  $(".download-company-template").on("click", function () {
    const fileType = $(this).data("type");
    downloadCSV("company", fileType);
  });
});
