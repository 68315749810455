$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");
  if (p === "events") {
    $(document).on(
      "shown.bs.modal",
      "#split-payment-cart-modal",
      function () {}
    );
  }
});
