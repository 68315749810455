$(document).ready(function () {
  let p = $("body").attr("p");
  let locationId = $("body").attr("event_id");
  let selectedTemplate;
  if (p === "template_import") {
    $(document).on("change", "#template-selector", function () {
      $("#imported-data-tables").empty();
      selectedTemplate = $(this).val();

      $(".template-group-container").attr("hidden", true).removeClass("d-flex");

      if (selectedTemplate === "product-group-template") {
        $("#product-group-template-import").attr("hidden", false);
        $("#product-group-template-import").addClass("d-flex");
      }
      if (selectedTemplate === "product-template") {
        $("#product-template-import").attr("hidden", false);
        $("#product-template-import").addClass("d-flex");
      }
      if (selectedTemplate === "supplier-template") {
        $("#supplier-template-import").attr("hidden", false);
        $("#supplier-template-import").addClass("d-flex");
      }
      if (selectedTemplate === "private-customer-template") {
        $("#private-customer-template-import").attr("hidden", false);
        $("#private-customer-template-import").addClass("d-flex");
      }
      if (selectedTemplate === "company-customer-template") {
        $("#company-customer-template-import").attr("hidden", false);
        $("#company-customer-template-import").addClass("d-flex");
      }
    });
  }
});
