$(document).ready(function () {
  let p = $("body").attr("p");
  const locationId = $("body").attr("event_id");

  if (p === "template_import") {
    let existingSupplierList = [];

    $(document).on("change", "#import-supplier", function () {
      const file = this.files[0];
      $(this).val("");
      $("#imported-data-tables").empty();

      if (!file) {
        alert("Vennligst velg en fil!");
        return;
      }

      const validTypes = [
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      const validExtensions = ["csv", "xlsx"];

      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      const fileType = file.type;

      if (
        !validExtensions.includes(fileExtension) ||
        !validTypes.includes(fileType)
      ) {
        alert(
          "Ugyldig filtype. Vennligst last opp en gyldig .csv- eller .xlsx-fil."
        );
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("location_id", locationId);

      $.ajax({
        url: "/api/v1/private/supplier/import-supplier",
        type: "POST",
        data: formData,
        contentType: false,
        processData: false,
        success: function (response) {
          if (response.exisitingSupplierList) {
            existingSupplierList = response.exisitingSupplierList;
          }
          if (response.html) {
            popupAlert();
            $("#imported-data-tables").empty();
            $("#imported-data-tables").html(response.html);
          }
        },
        error: function (xhr, status, error) {
          let errorMessage = "File upload failed";
          if (xhr.responseJSON && xhr.responseJSON.message) {
            errorMessage = xhr.responseJSON.message;
          } else if (xhr.responseText) {
            errorMessage = xhr.responseText;
          }
          alert(errorMessage);
          console.error(errorMessage);
        },
      });
    });

    $(document).on("click", "#overwrite-supplier-confirm", function () {
      $.ajax({
        url: "/api/v1/private/supplier/overwrite-supplier",
        type: "POST",
        data: { data: existingSupplierList },
        success: function (response) {
          if (response.success) {
            popupAlert();
            $("#supplier-overwrite-button").attr("hidden", true);
            $("#overwriteSupplierTemplateConfirmationModal").modal("hide");
          }
        },
        error: function (xhr, status, error) {
          $("#overwriteSupplierTemplateConfirmationModal").modal("hide");
          alert("Kunne ikke overskrive leverandører");
          console.error(error);
        },
      });
    });

    function downloadCSV(type) {
      const url = `/api/v1/private/supplier/download-template?type=${type}`;
      window.location.href = url;
    }

    $(".supplier-template-download").on("click", function () {
      const type = $(this).data("type");
      downloadCSV(type);
    });
  }
});
