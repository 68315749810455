$(document).ready(function () {
  let p = $("body").attr("p");
  let a = $("body").attr("a");

  if (p == "product_group_account_plan" || p == "general_account_plan") {
    $(document).on("click", ".toggle-table-row", function () {
      let rowId = $(this).attr("data-id");
      let $row = $(`.vat-account-row-${rowId}`);

      if ($row.is(":visible")) {
        $row.animate({ opacity: 0, height: "toggle" }, 200);
      } else {
        $row.animate({ opacity: 1, height: "toggle" }, 200);
      }
    });

    $(document).on("change", ".general-account-description", function (e) {
      e.preventDefault();
      saveGeneralAccountPlan();
    });

    $(document).on("change", ".value-input", function (e) {
      e.preventDefault();
      const valueInputId = $(this).attr("data-general-account-plan-id");
      const newValue = $(this).val();
      const oldValue = $(this).attr("data-old-value");
      const oldValueList = [];

      const isMobileViewVisible = $("#general-account-plan-list-mobile").is(
        ":visible"
      );

      // Select input fields based on the visible view
      const valueInputs = isMobileViewVisible
        ? $(".value-input-mobile")
        : $(".value-input");
      valueInputs.each(function () {
        const currentId = $(this).attr("data-general-account-plan-id");
        const currentValue = $(this).val();
        if (valueInputId != currentId) {
          oldValueList.push(currentValue);
        }
      });

      if (oldValueList.includes(newValue)) {
        $(this).val(oldValue);
        $(this).focus();
        alert("Dupliserte verdier er ikke tillatt. Angi unike verdier.");
        return;
      } else {
        saveGeneralAccountPlan();
      }
    });

    $(document).on("change", ".vat-account-input", function (e) {
      e.preventDefault();
      const newValue = $(this).val();
      const oldValue = $(this).attr("data-old-value");
      const productGroupId = $(this).attr("data-product-group-id");
      const productGroupName = $(this).attr("data-product-group-name");
      const planId = $(this).attr("data-product-group-account-plan-vat-id");
      const valueList = [];

      $(
        `input[name="vat_account"][data-product-group-id="${productGroupId}"]`
      ).each(function () {
        const curretnValue = $(this).val();

        if ($(this).attr("data-product-group-account-plan-vat-id") != planId) {
          valueList.push(curretnValue);
        }
      });

      if (valueList.includes(newValue)) {
        $(this).val(oldValue);
        $(this).focus();
        alert("Dupliserte MVA-konto funnet i varegruppe : " + productGroupName);
        return;
      } else {
        savePeoductGroupAccountPlan();
      }
    });

    function saveGeneralAccountPlan() {
      // Select all unique `data-general-account-plan-id` values
      const accountPlanData = [];
      let hasDuplicateValue = false;

      // Check which view is currently visible (mobile or desktop)
      const isMobileViewVisible = $("#general-account-plan-list-mobile").is(
        ":visible"
      );

      // Select input fields based on the visible view
      const descriptionInputs = isMobileViewVisible
        ? $('input[name="general-account-mobile-description"]')
        : $('input[name="general-account-description"]');

      descriptionInputs.each(function () {
        const id = $(this).attr("data-general-account-plan-id");
        const description = $(this).val();
        const valueInput = isMobileViewVisible
          ? $(
              `input[name="general-account-mobile-value"][data-general-account-plan-id="${id}"]`
            )
          : $(
              `input[name="general-account-value"][data-general-account-plan-id="${id}"]`
            );

        const value = valueInput.val() || 0;

        // Check for duplicate values
        if (accountPlanData.some((plan) => plan.value === value)) {
          hasDuplicateValue = true;

          return false; // Exit the loop if a duplicate is found
        }

        // Add data if it doesn't already exist in the array
        accountPlanData.push({
          general_account_plan_id: id,
          description: description,
          value: value,
        });
      });

      // Show error alert if duplicates were found
      if (hasDuplicateValue) {
        alert("Dupliserte verdier er ikke tillatt. Angi unike verdier.");
        return;
      }

      $.ajax({
        type: "POST",
        url: "/api/v1/private/account_plan/update_general_account",
        data: { accountPlanData },
      })
        .done(function (data) {
          if (data.html) {
            popupAlert();
            $("#general-account-plan-list-view").empty();
            $("#general-account-plan-list-view").html(data.html);
          }
        })
        .fail(function (data) {
          if (
            data.responseJSON.redirect_url !== undefined &&
            data.responseJSON.redirect_url !== null
          ) {
            document.location.href = data.responseJSON.redirect_url;
          }
        });
    }

    function savePeoductGroupAccountPlan() {
      var vatAccounts = [];
      var vatAccountGroups = {};

      // Collect VAT accounts grouped by product group ID
      $(".vat-account-input").each(function () {
        var groupId = $(this).closest("tr").attr("data-product-group-id");
        var groupName = $(this).closest("tr").attr("data-product-group-name");

        var vatAccountData = {
          product_group_account_plan_vat_id: $(this).attr(
            "data-product-group-account-plan-vat-id"
          ),
          vat_account: $(this).val(),
        };

        // Initialize group if it doesn't exist
        if (!vatAccountGroups[groupId]) {
          vatAccountGroups[groupId] = { groupName: groupName, accounts: [] };
        }

        vatAccountGroups[groupId].accounts.push(vatAccountData.vat_account);
        vatAccounts.push(vatAccountData);
      });

      // Check for duplicates within each group
      var hasDuplicates = false;
      $.each(vatAccountGroups, function (groupId, groupData) {
        var uniqueAccounts = new Set(groupData.accounts);

        if (uniqueAccounts.size !== groupData.accounts.length) {
          hasDuplicates = true;
          alert(
            "Dupliserte MVA-konto funnet i varegruppe : " + groupData.groupName
          );
          return false; // Exit loop if duplicates are found
        }
      });

      // Proceed if no duplicates are found
      if (!hasDuplicates) {
        $.ajax({
          type: "POST",
          url: "/api/v1/private/account_plan/update_product_group_account",
          data: { vatAccounts },
        })
          .done(function (data) {
            if (data.html) {
              popupAlert();
              $("#product-group-account-plan-list-view").empty();
              $("#product-group-account-plan-list-view").html(data.html);
            }
          })
          .fail(function (data) {
            if (
              data.responseJSON.redirect_url !== undefined &&
              data.responseJSON.redirect_url !== null
            ) {
              document.location.href = data.responseJSON.redirect_url;
            }
          });
      }
    }
  }
});
