let p = $("body").attr("p");
let a = $("body").attr("a");
let filterBy = $("body").attr("filter_by") || null;
let startDateQuery = $("body").attr("start_date") || null;
let endDateQuery = $("body").attr("end_date") || null;
let customPeriodQuery = $("body").attr("custom_period") || null;
let prevSelectedPeriodQuery = $("body").attr("prev_selected_period") || null;
let locationId = $("body").attr("event_id");
let startDate = moment().format("YYYY-MM-DD") + "T00:00";
let endDate = moment().format("YYYY-MM-DD") + "T23:59";
let period = "day";
let supplierId = $("#supplier-id").val();
let productGroupUniqueId = $("#product-group-id").val();
let topProductGroupUniqueId = $("#top-product-group-id").val();
let userId = $("#user-id").val();
let platform = "all";
let prevSelectedPeriod = "day";
let isDateChanged = false;
let durationOfstartAndEndDate = 0;
let customPeriod = "";
let isMobile = false;
let isTablet = false;
let isDesktop = false;
var deviceWidth = $(window).width();
let customerId = 0;
let compareBy = null;

if (deviceWidth < 768) {
  isMobile = true;
} else if (deviceWidth >= 768 && deviceWidth < 992) {
  isTablet = true;
} else {
  isDesktop = true;
}

function dateAndPeriodValidation(startedDate, endedDate) {
  let start = moment(startedDate);
  let end = moment(endedDate);

  if (start.isAfter(end)) {
    $("#start-date").val(startDate);
    $("#end-date").val(endDate);
    return alert("end date cannot be later than start date");
  } else {
    return true;
  }
}

if (filterBy) {
  let periodTitleOfFilterBy = "";
  prevSelectedPeriod = prevSelectedPeriodQuery
    ? prevSelectedPeriodQuery
    : prevSelectedPeriod;
  if (filterBy === "week") {
    $("#period-week").prop("checked", true);
    period = "week";
    prevSelectedPeriod = "week";
    customPeriod = customPeriodQuery ? customPeriodQuery : "day";
    startDate = startDateQuery
      ? startDateQuery
      : moment().startOf("week").format("YYYY-MM-DD") + "T00:00";
    endDate = endDateQuery
      ? endDateQuery
      : moment().endOf("week").format("YYYY-MM-DD") + "T23:59";
    periodTitleOfFilterBy = "Uke nr" + " " + moment(startDate).isoWeek();
  } else if (filterBy === "day") {
    $("#period-day").prop("checked", true);
    customPeriod = customPeriodQuery ? customPeriodQuery : "time";
    startDate = startDateQuery
      ? startDateQuery
      : moment().startOf("week").format("YYYY-MM-DD") + "T00:00";
    endDate = endDateQuery
      ? endDateQuery
      : moment().endOf("week").format("YYYY-MM-DD") + "T23:59";
    $(`#custom-period option[value="time"]`).prop("selected", true);

    periodTitleOfFilterBy = moment.utc(startDate).format("ddd - DD.MM.YYYY");
  } else if (filterBy === "month") {
    $("#period-month").prop("checked", true);
    period = "month";
    prevSelectedPeriod = "month";
    startDate = startDateQuery
      ? startDateQuery
      : moment().startOf("week").format("YYYY-MM-DD") + "T00:00";
    endDate = endDateQuery
      ? endDateQuery
      : moment().endOf("week").format("YYYY-MM-DD") + "T23:59";
    if (isDesktop) {
      customPeriod = "day";
      $(`#custom-period option[value="time"]`).prop("selected", false);
      $(`#custom-period option[value="day"]`).prop("selected", true);
    } else {
      customPeriod = "week";
      $(`#custom-period option[value="day"]`).prop("selected", false);
      $(`#custom-period option[value="week"]`).prop("selected", true);
      $(`#custom-period option[value="day"]`).prop("disabled", true);
    }
    periodTitleOfFilterBy = moment(startDate).format("MMMM");
  } else {
    period = "custom";
    customPeriod = customPeriodQuery ? customPeriodQuery : customPeriod;
    prevSelectedPeriod = prevSelectedPeriodQuery
      ? prevSelectedPeriodQuery
      : prevSelectedPeriod;
    startDate = startDateQuery;
    endDate = endDateQuery;
    $("#period-custom").prop("checked", true);
    $(`#custom-period option[value=${customPeriod}]`).prop("selected", true);
    $("#start-date").val(startDate);
    $("#end-date").val(endDate);
    if (prevSelectedPeriod === "day") {
      periodTitleOfFilterBy = moment.utc(startDate).format("ddd - DD.MM.YYYY");
    } else if (prevSelectedPeriod === "week") {
      periodTitleOfFilterBy = "Uke nr" + " " + moment(startDate).isoWeek();
    } else if (prevSelectedPeriod === "month") {
      periodTitleOfFilterBy = moment(startDate).format("MMMM");
    } else {
      if (startDate === endDate) {
        $("#custom-period").prop("disabled", true);
      } else {
        $("#custom-period option[value='time']").addClass("disabledOption");
        $("#custom-period option[value='time']").prop("disabled", true);
      }

      periodTitleOfFilterBy =
        moment(startDate).format("DD-MM-YYYY") +
        " : " +
        moment(endDate).format("DD-MM-YYYY");
    }
  }
  setTimeout(() => $("#period-title").text(periodTitleOfFilterBy), 50);
} else {
  $("#period-day").prop("checked", true);
}

function handlePeriodChange(callback) {
  $(document).on("change", 'input[type="radio"]', function (e) {
    period = $('input[type="radio"]:checked').val();
    prevSelectedPeriod = period !== "custom" ? period : prevSelectedPeriod;

    if (period === "day") {
      startDate = moment().format("YYYY-MM-DD") + "T00:00";
      endDate = moment().format("YYYY-MM-DD") + "T23:59";
      const currentDayFormatted = moment().format("ddd - DD.MM.YYYY");
      customPeriod = "time";
      $("#period-title").text(currentDayFormatted);
      if (compareBy && compareBy !== "0") {
        compareBy = "day-compare";
        $("#compare").val("day-compare").trigger("change.select2");
      }
    } else if (period === "week") {
      startDate = moment().startOf("week").format("YYYY-MM-DD") + "T00:00";
      endDate = moment().endOf("week").format("YYYY-MM-DD") + "T23:59";
      const currentWeekNumber = moment().utc().isoWeek();
      customPeriod = "day";
      $(`#custom-period option[value="day"]`).prop("selected", true);
      $("#period-title").text("Uke nr" + " " + currentWeekNumber);
      if (compareBy && compareBy !== "0") {
        compareBy = "week-compare";
        $("#compare").val("week-compare").trigger("change.select2");
      }
    } else if (period === "month") {
      startDate = moment().startOf("month").format("YYYY-MM-DD") + "T00:00";
      endDate = moment().endOf("month").format("YYYY-MM-DD") + "T23:59";
      durationOfstartAndEndDate = 30;
      displayGraphDynamically(durationOfstartAndEndDate);
      if (isDesktop) {
        customPeriod = "day";
        $(`#custom-period option[value="time"]`).prop("selected", false);
        $(`#custom-period option[value="day"]`).prop("selected", true);
      } else {
        customPeriod = "week";
        $(`#custom-period option[value="day"]`).prop("selected", false);
        $(`#custom-period option[value="week"]`).prop("selected", true);
        $(`#custom-period option[value="day"]`).prop("disabled", true);
      }
      const currentMonth = moment().format("MMMM");
      $("#period-title").text(currentMonth);
      if (compareBy && compareBy !== "0") {
        compareBy = "month-compare";
        $("#compare").val("month-compare").trigger("change.select2");
      }
    } else {
      $("#period-title").text("Custom");
      $("#compare").val("0").trigger("change.select2");
      compareBy = null;
      if (startDate == endDate) {
        $("#custom-period option[value='time']").prop("selected", true);
        $("#custom-period").prop("disabled", true);
      } else {
        $("#custom-period option[value='time']").prop("disabled", true);
        $("#custom-period").prop("disabled", false);
      }
    }
    customPeriod = $("#custom-period").val();
    $("#start-date").val(startDate);
    $("#end-date").val(endDate);
    toggleCustomDatePicker();

    callback(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      customPeriod,
      topProductGroupUniqueId,
      customerId,
      compareBy
    );
  });
}

function handleCustomerIdChange(callback) {
  $(document).on("change", "#customer-id", function (e) {
    e.preventDefault();

    customerId = $(this).val();
    callback(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      customPeriod,
      topProductGroupUniqueId,
      customerId,
      compareBy
    );
  });
}

function handleCustomPeriodChange(callback) {
  $(document).on("change", "#custom-period", function (e) {
    e.preventDefault();
    customPeriod = $(this).val();

    callback(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      customPeriod,
      topProductGroupUniqueId,
      customerId,
      compareBy
    );
  });
}

function handleTopProductGroupChange(callback) {
  $(document).on("change", "#top-product-group-id", function (e) {
    e.preventDefault();
    $("#product-group-id").val(0);
    topProductGroupUniqueId = $(this).val();
    callback(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      customPeriod,
      topProductGroupUniqueId,
      customerId,
      compareBy
    );
  });
}

function handleProductGroupChange(callback) {
  $(document).on("change", "#product-group-id", function (e) {
    e.preventDefault();
    $("#top-product-group-id").val(0);
    $("select[name=top-product-group-id]").select2({
      theme: "bootstrap4",
    });
    productGroupUniqueId = $(this).val();
    callback(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      customPeriod,
      topProductGroupUniqueId,
      customerId,
      compareBy
    );
  });
}

function handleSupplierIdChange(callback) {
  $(document).on("change", "#supplier-id", function (e) {
    e.preventDefault();
    supplierId = $(this).val();
    callback(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      customPeriod,
      topProductGroupUniqueId,
      customerId,
      compareBy
    );
  });
}

function handleUserChange(callback) {
  $(document).on("change", "#user-id", function (e) {
    e.preventDefault();
    userId = $(this).val();
    callback(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      customPeriod,
      topProductGroupUniqueId,
      customerId,
      compareBy
    );
  });
}

function handlePlatformChange(callback) {
  $(document).on("change", "#platform", function (e) {
    e.preventDefault();
    platform = $(this).val();

    if (platform === "self-service") {
      userId = 0;
      $("#user-id").val(userId);
      $("#user-id").prop("disabled", true);
    } else {
      $("#user-id").prop("disabled", false);
    }
    callback(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      customPeriod,
      topProductGroupUniqueId,
      customerId,
      compareBy
    );
  });
}

function handleEndDateChange(callback) {
  $(document).on("change", "#end-date", function (e) {
    e.preventDefault();
    let currentendDate = $(this).val();
    if (dateAndPeriodValidation(startDate, currentendDate)) {
      isDateChanged = true;
      endDate = currentendDate;
      period = $('input[name="period"]:checked').val();
      if (startDate.split("T")[0] == endDate.split("T")[0]) {
        $("#custom-period option[value='time']").prop("selected", true);
        $("#custom-period").prop("disabled", true);
      } else {
        durationOfstartAndEndDate = moment
          .utc(endDate)
          .diff(moment(startDate.split("T")[0]), "days");
        displayGraphDynamically(durationOfstartAndEndDate);

        $("#custom-period").prop("disabled", false);
      }

      callback(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        topProductGroupUniqueId,
        customerId,
        compareBy
      );
      prevSelectedPeriod = "custom";
      $("#period-title").text(
        moment(startDate).format("DD-MM-YYYY") +
          " : " +
          moment(endDate).format("DD-MM-YYYY")
      );
    }
  });
}

function handleStartDateChange(callback) {
  $(document).on("change", "#start-date", function (e) {
    e.preventDefault();
    let choosenDate = $(this).val();

    if (dateAndPeriodValidation(choosenDate, endDate)) {
      startDate = choosenDate;
      isDateChanged = true;
      period = $('input[name="period"]:checked').val();
      if (startDate.split("T")[0] == endDate.split("T")[0]) {
        $("#custom-period option[value='time']").prop("selected", true);
        $("#custom-period").prop("disabled", true);
      } else {
        durationOfstartAndEndDate = moment
          .utc(endDate.split("T")[0])
          .diff(moment(startDate), "days");
        displayGraphDynamically(durationOfstartAndEndDate);
        $("#custom-period").prop("disabled", false);
      }
      callback(
        startDate,
        endDate,
        period,
        supplierId,
        productGroupUniqueId,
        locationId,
        userId,
        platform,
        customPeriod,
        topProductGroupUniqueId,
        customerId,
        compareBy
      );
      prevSelectedPeriod = "custom";
      $("#period-title").text(
        moment(startDate).format("DD-MM-YYYY") +
          " : " +
          moment(endDate).format("DD-MM-YYYY")
      );
    }
  });
}

function handlePreviousButton(callback) {
  $(document).on("click", "#previous-button", function (e) {
    e.preventDefault();
    let periodTitle = "";
    if (
      period === "day" ||
      (prevSelectedPeriod === "day" && !isDateChanged && period === "custom")
    ) {
      startDate =
        moment.utc(startDate).subtract(1, "day").format("YYYY-MM-DD") +
        "T00:00";
      endDate =
        moment.utc(endDate).subtract(1, "day").format("YYYY-MM-DD") + "T23:59";
      periodTitle = moment(startDate).format("ddd - DD.MM.YYYY");
    }
    if (
      period === "week" ||
      (prevSelectedPeriod === "week" && !isDateChanged && period === "custom")
    ) {
      startDate =
        moment.utc(startDate).subtract(1, "week").format("YYYY-MM-DD") +
        "T00:00";
      endDate =
        moment(endDate).subtract(1, "week").format("YYYY-MM-DD") + "T23:59";
      const currentWeekNumber = moment(startDate).isoWeek();
      periodTitle = "Uke nr" + " " + currentWeekNumber;
    }

    if (
      period === "month" ||
      (prevSelectedPeriod === "month" && !isDateChanged && period === "custom")
    ) {
      startDate =
        moment.utc(startDate).subtract(1, "month").format("YYYY-MM-DD") +
        "T00:00";
      endDate =
        moment(endDate).subtract(1, "month").format("YYYY-MM-DD") + "T23:59";
      periodTitle = moment(startDate).format("MMMM");
    }
    if (period === "custom" && prevSelectedPeriod === "custom") {
      let previousStartDate = moment(startDate);
      let previousEndDate = moment(endDate);

      endDate =
        moment(startDate).subtract(1, "day").format("YYYY-MM-DD") + "T23:59";

      durationOfstartAndEndDate = previousEndDate.diff(
        previousStartDate,
        "days"
      );

      startDate =
        moment
          .utc(endDate)
          .subtract(durationOfstartAndEndDate, "days")
          .format("YYYY-MM-DD") + "T00:00";
      periodTitle =
        moment(startDate).format("DD-MM-YYYY") +
        " : " +
        moment(endDate).format("DD-MM-YYYY");
    }
    $("#start-date").val(startDate);
    $("#end-date").val(endDate);
    $("#period-title").text(periodTitle);

    callback(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      customPeriod,
      topProductGroupUniqueId,
      customerId,
      compareBy
    );
  });
}

function handleNextButton(callback) {
  $(document).on("click", "#next-button", function (e) {
    e.preventDefault();
    let periodTitle = "";
    if (
      period === "day" ||
      (prevSelectedPeriod === "day" && !isDateChanged && period === "custom")
    ) {
      startDate =
        moment.utc(startDate).add(1, "day").format("YYYY-MM-DD") + "T00:00";
      endDate =
        moment.utc(endDate).add(1, "day").format("YYYY-MM-DD") + "T23:59";
      periodTitle = moment(startDate).format("ddd - DD.MM.YYYY");
    }
    if (
      period === "week" ||
      (prevSelectedPeriod === "week" && !isDateChanged && period === "custom")
    ) {
      startDate =
        moment(startDate).add(1, "week").format("YYYY-MM-DD") + "T00:00";
      endDate = moment(endDate).add(1, "week").format("YYYY-MM-DD") + "T23:59";
      const currentWeekNumber = moment(startDate).isoWeek();
      periodTitle = "Uke nr" + " " + currentWeekNumber;
    }

    if (
      period === "month" ||
      (prevSelectedPeriod === "month" && !isDateChanged && period === "custom")
    ) {
      startDate =
        moment(startDate).add(1, "month").format("YYYY-MM-DD") + "T00:00";
      endDate = moment(endDate).add(1, "month").format("YYYY-MM-DD") + "T23:59";
      periodTitle = moment(startDate).format("MMMM");
    }

    if (period === "custom" && prevSelectedPeriod === "custom") {
      let previousStartDate = moment(startDate);
      let previousEndDate = moment(endDate);

      startDate = moment(endDate).add(1, "day").format("YYYY-MM-DD") + "T00:00";

      durationOfstartAndEndDate = previousEndDate.diff(
        previousStartDate,
        "days"
      );

      endDate =
        moment
          .utc(startDate)
          .add(durationOfstartAndEndDate, "days")
          .format("YYYY-MM-DD") + "T23:59";
      periodTitle =
        moment(startDate).format("DD-MM-YYYY") +
        " : " +
        moment(endDate).format("DD-MM-YYYY");
    }
    $("#start-date").val(startDate);
    $("#end-date").val(endDate);
    $("#period-title").text(periodTitle);
    callback(
      startDate,
      endDate,
      period,
      supplierId,
      productGroupUniqueId,
      locationId,
      userId,
      platform,
      customPeriod,
      topProductGroupUniqueId,
      customerId,
      compareBy
    );
  });
}

function handleCompareByChange(callback) {
  $(document).on("change", "#compare", function (e) {
    e.preventDefault();
    compareBy = $(this).val();

    if (compareBy === "day-compare") {
      period = "day";
      $("#period-day").prop("checked", true);
    } else if (compareBy === "week-compare") {
      period = "week";
      $("#period-week").prop("checked", true);
    } else if (compareBy === "month-compare") {
      period = "month";
      $("#period-month").prop("checked", true);
    } else {
      compareBy = null;
    }
    $('input[type="radio"]:checked').trigger("change");
  });
}

function displayGraphDynamically(durationOfstartAndEndDate) {
  if (isMobile) {
    if (durationOfstartAndEndDate <= 7) {
      customPeriod = "day";
      $("#custom-period option[value='day']").prop("disabled", false);
      $("#custom-period option[value='day']").prop("selected", true);
      $("#custom-period option[value='week']").prop("disabled", false);
      $("#custom-period option[value='time']").prop("disabled", true);
      $("#custom-period option[value='time']").addClass("disabledOption");
      $("#custom-period option[value='day']").removeClass("disabledOption");
      $("#custom-period option[value='week']").removeClass("disabledOption");
    } else if (
      durationOfstartAndEndDate >= 8 &&
      durationOfstartAndEndDate < 49
    ) {
      //week
      customPeriod = "week";
      $(`#custom-period option[value="week"]`).prop("selected", true);
      $("#custom-period option[value='week']").prop("disabled", false);
      $("#custom-period option[value='time']").prop("disabled", true);
      $("#custom-period option[value='day']").prop("disabled", true);
      $("#custom-period option[value='time']").addClass("disabledOption");
      $("#custom-period option[value='day']").addClass("disabledOption");
      $("#custom-period option[value='week']").removeClass("disabledOption");
    } else {
      //month
      customPeriod = "month";
      $(`#custom-period option[value="week"]`).prop("selected", false);
      $(`#custom-period option[value="month"]`).prop("selected", true);
      $("#custom-period option[value='time']").prop("disabled", true);
      $("#custom-period option[value='day']").prop("disabled", true);
      $("#custom-period option[value='week']").prop("disabled", true);
      $("#custom-period option[value='time']").addClass("disabledOption");
      $("#custom-period option[value='day']").addClass("disabledOption");
      $("#custom-period option[value='week']").addClass("disabledOption");
    }
  } else if (isTablet) {
    if (durationOfstartAndEndDate <= 14) {
      //day
      customPeriod = "day";
      $("#custom-period option[value='day']").prop("disabled", false);
      $("#custom-period option[value='day']").prop("selected", true);
      $("#custom-period option[value='week']").prop("disabled", false);
      $("#custom-period option[value='time']").prop("disabled", true);
      $("#custom-period option[value='time']").addClass("disabledOption");
      $("#custom-period option[value='day']").removeClass("disabledOption");
      $("#custom-period option[value='week']").removeClass("disabledOption");
    } else if (
      durationOfstartAndEndDate >= 15 &&
      durationOfstartAndEndDate < 98
    ) {
      //week
      customPeriod = "week";
      $(`#custom-period option[value="week"]`).prop("selected", true);
      $("#custom-period option[value='week']").prop("disabled", false);
      $("#custom-period option[value='time']").prop("disabled", true);
      $("#custom-period option[value='day']").prop("disabled", true);
      $("#custom-period option[value='time']").addClass("disabledOption");
      $("#custom-period option[value='day']").addClass("disabledOption");
      $("#custom-period option[value='week']").removeClass("disabledOption");
    } else {
      //month
      customPeriod = "month";
      $(`#custom-period option[value="week"]`).prop("selected", false);
      $(`#custom-period option[value="month"]`).prop("selected", true);
      $("#custom-period option[value='time']").prop("disabled", true);
      $("#custom-period option[value='day']").prop("disabled", true);
      $("#custom-period option[value='week']").prop("disabled", true);
      $("#custom-period option[value='time']").addClass("disabledOption");
      $("#custom-period option[value='day']").addClass("disabledOption");
      $("#custom-period option[value='week']").addClass("disabledOption");
    }
  } else {
    if (durationOfstartAndEndDate <= 31) {
      //day
      customPeriod = "day";
      $("#custom-period option[value='day']").prop("disabled", false);
      $("#custom-period option[value='day']").prop("selected", true);
      $("#custom-period option[value='week']").prop("disabled", false);
      $("#custom-period option[value='time']").prop("disabled", true);
      $("#custom-period option[value='time']").addClass("disabledOption");
      $("#custom-period option[value='day']").removeClass("disabledOption");
      $("#custom-period option[value='week']").removeClass("disabledOption");
    } else if (
      durationOfstartAndEndDate >= 32 &&
      durationOfstartAndEndDate < 217
    ) {
      //week
      customPeriod = "week";
      $(`#custom-period option[value="week"]`).prop("selected", true);
      $("#custom-period option[value='week']").prop("disabled", false);
      $("#custom-period option[value='time']").prop("disabled", true);
      $("#custom-period option[value='day']").prop("disabled", true);
      $("#custom-period option[value='time']").addClass("disabledOption");
      $("#custom-period option[value='day']").addClass("disabledOption");
      $("#custom-period option[value='week']").removeClass("disabledOption");
    } else {
      //month
      customPeriod = "month";
      $(`#custom-period option[value="week"]`).prop("selected", false);
      $(`#custom-period option[value="month"]`).prop("selected", true);
      $("#custom-period option[value='time']").prop("disabled", true);
      $("#custom-period option[value='day']").prop("disabled", true);
      $("#custom-period option[value='week']").prop("disabled", true);
      $("#custom-period option[value='time']").addClass("disabledOption");
      $("#custom-period option[value='day']").addClass("disabledOption");
      $("#custom-period option[value='week']").addClass("disabledOption");
    }
  }
}

function toggleCustomDatePicker() {
  let customDatePicker = $(".custom-date-picker");

  if (period === "custom") {
    customDatePicker.show();
  } else {
    customDatePicker.hide();
  }
}

$(document).ready(function () {
  $(
    "select[name=supplier-id],select[name=product-group-id],select[name=user-id],select[name=platform],select[name=top-product-group-id],select[name=customer-id],select[name=compare],select[name=product-group-id]"
  ).select2({
    theme: "bootstrap4",
  });
});
